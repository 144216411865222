/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface EmojiProps extends React.SVGAttributes<SVGElement> {
size?: string | number;
}
const Emoji: React.FC<EmojiProps> = ({size, ...props}) => (
  <svg viewBox="0 0 20 20" fill="currentColor" width={ size || "20" } height={ size || "20" } {...props}>
    <path d="M4.57981 4.5798C6.04386 3.11575 8.02953 2.29326 10.1 2.29326C12.1705 2.29326 14.1561 3.11575 15.6202 4.5798C17.0842 6.04384 17.9067 8.02952 17.9067 10.1C17.9067 12.1705 17.0842 14.1561 15.6202 15.6202C14.1561 17.0842 12.1705 17.9067 10.1 17.9067C8.02953 17.9067 6.04386 17.0842 4.57981 15.6202C3.11576 14.1561 2.29327 12.1705 2.29327 10.1C2.29327 8.02952 3.11576 6.04384 4.57981 4.5798ZM10.1 3.70672C8.4044 3.70672 6.77825 4.38029 5.57928 5.57927C4.38031 6.77824 3.70673 8.40439 3.70673 10.1C3.70673 11.7956 4.38031 13.4217 5.57928 14.6207C6.77825 15.8197 8.4044 16.4933 10.1 16.4933C11.7956 16.4933 13.4218 15.8197 14.6207 14.6207C15.8197 13.4217 16.4933 11.7956 16.4933 10.1C16.4933 8.40439 15.8197 6.77824 14.6207 5.57927C13.4218 4.38029 11.7956 3.70672 10.1 3.70672Z"
      fill="currentColor" fillRule="evenodd" clipRule="evenodd" />
    <path d="M6.25644 10.5141C6.63105 10.4044 7.0236 10.6192 7.13324 10.9938C7.32099 11.6352 7.71156 12.1986 8.24643 12.5993C8.7813 13.0001 9.43165 13.2167 10.1 13.2167C10.7683 13.2167 11.4187 13.0001 11.9536 12.5993C12.4884 12.1986 12.879 11.6352 13.0668 10.9938C13.1764 10.6192 13.569 10.4044 13.9436 10.5141C14.3182 10.6237 14.5329 11.0163 14.4233 11.3909C14.1497 12.3256 13.5805 13.1465 12.8011 13.7305C12.0217 14.3145 11.074 14.6301 10.1 14.6301C9.12605 14.6301 8.17833 14.3145 7.39889 13.7305C6.61945 13.1465 6.05028 12.3256 5.77669 11.3909C5.66705 11.0163 5.88184 10.6237 6.25644 10.5141Z"
      fill="currentColor" fillRule="evenodd" clipRule="evenodd" />
    <path d="M13 8C13 8.55228 12.5523 9 12 9 11.4477 9 11 8.55228 11 8 11 7.44772 11.4477 7 12 7 12.5523 7 13 7.44772 13 8zM9 8C9 8.55228 8.55228 9 8 9 7.44772 9 7 8.55228 7 8 7 7.44772 7.44772 7 8 7 8.55228 7 9 7.44772 9 8z"
      fill="currentColor" />
  </svg>
);
Emoji.displayName = 'Emoji';
export default Emoji;
/* tslint:enable */
/* eslint-enable */
