import axios from 'axios';
import { ActivityLog, Column, Item, Tag, User } from '../types';
import { toCamelCase } from '../utils';
import { getToken } from '../stores/storage';

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_SERVER_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.request.use(config => {
  const token = getToken();

  if (token) {
    config.headers['Authorization'] = token;
  }
  return config;
});

apiClient.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      OnUnauthorizeManager.handle();
    }
    return Promise.reject(error);
  }
);

export async function setUserInfo(
  email: string | null,
  updateParams: { favIds: string[]; date: string; settingsIds: string[] | null }
) {
  if (!email) return;

  try {
    const { data } = await apiClient.post('/set-user-info', {
      email,
      ...updateParams,
    });

    return data;
  } catch (error) {
    console.error('Failed to set user info', { error });
  }
}

export async function getUserInfo(email: string) {
  try {
    const { data } = await apiClient.get('/get-user-info', { params: { email } });

    return data;
  } catch (error) {
    console.error('Failed to get user info.', { error });

    throw error;
  }
}

export async function getBoardStructure(boardId: number) {
  try {
    const response = toCamelCase(
      await apiClient.get('/get-board-structure', { params: { boardId } })
    ) as {
      data: {
        items: Item[];
        columns: Column[];
        users: User[];
        tagsInfo: Record<string, Tag>;
      };
    };

    return response.data;
  } catch (error) {
    console.error('Failed to get board structure.', { error });

    throw error;
  }
}

export async function getItemsAssets(boardId: number, itemsId: string[]) {
  try {
    const response = toCamelCase(
      await apiClient.get('/get-items-assets', {
        params: { boardId, itemsId: JSON.stringify(itemsId) },
      })
    ) as { data: Record<string, string> };

    return response.data;
  } catch (error) {
    console.error('Failed to get items assets.', { error });

    throw error;
  }
}

export async function getActivityLogs({
  boardId,
  itemsId,
  columnId,
}: {
  boardId: number;
  itemsId: number[];
  columnId: string;
}) {
  try {
    const response = toCamelCase(
      await apiClient.get('/get-item-activity-logs', {
        params: { boardId, itemsId: JSON.stringify(itemsId), columnId },
      })
    ) as { data: ActivityLog[] };

    return response.data;
  } catch (error) {
    console.error('Failed to get activity logs.', { error });

    throw error;
  }
}

export async function exchangeGoogleToken(googleToken: string) {
  try {
    const response = await apiClient.post('/auth', {
      googleToken,
    });

    const data = response.data;
    return data as { email: string; token: string };
  } catch (error) {
    console.error('Error exchanging Google token:', error);
    throw error;
  }
}

type VoidFn = () => void;
export class OnUnauthorizeManager {
  private static callback: VoidFn | null = null;

  static setListener(callback: VoidFn) {
    this.callback = callback;
  }

  static handle() {
    if (!this.callback) {
      console.error(
        'OnUnauthorizeManager.callaback was not set! Use "OnUnauthorizeManager.setListener" to set correct behavior'
      );
      return;
    }

    this.callback();
  }
}
