/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface StrikethroughSProps extends React.SVGAttributes<SVGElement> {
size?: string | number;
}
const StrikethroughS: React.FC<StrikethroughSProps> = ({size, ...props}) => (
  <svg viewBox="0 0 20 20" fill="currentColor" width={ size || "20" } height={ size || "20" } {...props}>
    <g fill="currentColor" clipPath="url(#clip0)" fillRule="evenodd" clipRule="evenodd">
      <path d="M13.0282 11.2033C13.3426 10.9336 13.8161 10.9698 14.0858 11.2842 14.4291 11.6843 14.687 12.1504 14.8436 12.6538 15.0003 13.1572 15.0524 13.6873 14.9968 14.2116 14.9411 14.7359 14.7789 15.2432 14.5201 15.7026 14.2613 16.1619 13.9114 16.5635 13.4918 16.8826 13.4818 16.8902 13.4717 16.8975 13.4614 16.9045 12.4484 17.5982 11.2604 17.9925 10.0336 18.0423 8.80686 18.092 7.59087 17.7953 6.52494 17.186L6.52081 17.1836 6.52082 17.1836 5.50024 16.5916C5.14195 16.3838 5.01997 15.9248 5.22781 15.5665 5.43565 15.2082 5.89459 15.0863 6.25289 15.2941L7.27135 15.8849C8.09218 16.3536 9.02836 16.5818 9.97283 16.5435 10.9116 16.5054 11.8209 16.2055 12.598 15.6778 12.8485 15.4842 13.0578 15.2422 13.2133 14.9662 13.3718 14.685 13.4711 14.3743 13.5051 14.0533 13.5392 13.7323 13.5073 13.4077 13.4114 13.0995 13.3154 12.7913 13.1575 12.5059 12.9473 12.2609 12.6776 11.9466 12.7138 11.4731 13.0282 11.2033zM9.11638 1.97235C10.7629 1.87328 12.544 2.36625 13.7644 2.97738 14.1348 3.16285 14.2847 3.61344 14.0992 3.98382 13.9137 4.35419 13.4631 4.50408 13.0928 4.31861 12.056 3.79947 10.5371 3.38957 9.20648 3.46964 8.54773 3.50928 7.97837 3.66624 7.53823 3.94689 7.11257 4.21832 6.77115 4.6274 6.58114 5.25581 6.47832 5.60027 6.46649 5.96549 6.54681 6.31588 6.62278 6.64732 6.77875 6.95479 7.0007 7.2116 7.84731 7.86352 8.77239 8.40678 9.75432 8.82861 10.1349 8.9921 10.3109 9.43316 10.1474 9.81375 9.9839 10.1943 9.54283 10.3703 9.16225 10.2068 8.04211 9.72561 6.98817 9.10298 6.02616 8.35411 5.99297 8.32827 5.96201 8.29969 5.93361 8.26866 5.51555 7.81191 5.22307 7.25456 5.08473 6.65102 4.94639 6.04748 4.96691 5.41838 5.14429 4.82514L5.14488 4.82317 5.14488 4.82317C5.43779 3.8533 6.00123 3.14796 6.73177 2.68214 7.44806 2.2254 8.28655 2.02229 9.11638 1.97235z"
      />
      <path d="M1.82143 9.56686C1.82143 9.15265 2.15721 8.81686 2.57143 8.81686H17.4286C17.8428 8.81686 18.1786 9.15265 18.1786 9.56686C18.1786 9.98108 17.8428 10.3169 17.4286 10.3169H2.57143C2.15721 10.3169 1.82143 9.98108 1.82143 9.56686Z"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill="#fff" transform="translate(2 2)" d="M0 0H16V16H0z" />
      </clipPath>
    </defs>
  </svg>
);
StrikethroughS.displayName = 'StrikethroughS';
export default StrikethroughS;
/* tslint:enable */
/* eslint-enable */
