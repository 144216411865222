/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface CutProps extends React.SVGAttributes<SVGElement> {
size?: string | number;
}
const Cut: React.FC<CutProps> = ({size, ...props}) => (
  <svg viewBox="0 0 20 20" fill="currentColor" width={ size || "20" } height={ size || "20" } {...props}>
    <path d="M2.85584 12.6768C3.44385 12.0888 4.24135 11.7584 5.07292 11.7584 5.90448 11.7584 6.70199 12.0888 7.28999 12.6768 7.878 13.2648 8.20833 14.0623 8.20833 14.8938 8.20833 15.7254 7.878 16.5229 7.28999 17.1109 6.70199 17.6989 5.90448 18.0293 5.07292 18.0293 4.24135 18.0293 3.44385 17.6989 2.85584 17.1109 2.26784 16.5229 1.9375 15.7254 1.9375 14.8938 1.9375 14.0623 2.26784 13.2648 2.85584 12.6768zM5.07292 13.2584C4.63918 13.2584 4.2232 13.4307 3.9165 13.7374 3.6098 14.0441 3.4375 14.4601 3.4375 14.8938 3.4375 15.3276 3.6098 15.7436 3.9165 16.0503 4.2232 16.357 4.63918 16.5293 5.07292 16.5293 5.50666 16.5293 5.92263 16.357 6.22933 16.0503 6.53603 15.7436 6.70833 15.3276 6.70833 14.8938 6.70833 14.4601 6.53603 14.0441 6.22933 13.7374 5.92263 13.4307 5.50666 13.2584 5.07292 13.2584zM2.85584 3.13508C3.44385 2.54707 4.24135 2.21674 5.07292 2.21674 5.90448 2.21674 6.70199 2.54707 7.28999 3.13508 7.878 3.72308 8.20833 4.52059 8.20833 5.35215 8.20833 6.18372 7.878 6.98122 7.28999 7.56923 6.70199 8.15723 5.90448 8.48757 5.07292 8.48757 4.24135 8.48757 3.44385 8.15723 2.85584 7.56923 2.26784 6.98122 1.9375 6.18372 1.9375 5.35215 1.9375 4.52059 2.26784 3.72308 2.85584 3.13508zM5.07292 3.71674C4.63918 3.71674 4.2232 3.88904 3.9165 4.19574 3.6098 4.50244 3.4375 4.91841 3.4375 5.35215 3.4375 5.78589 3.6098 6.20187 3.9165 6.50857 4.2232 6.81527 4.63918 6.98757 5.07292 6.98757 5.50666 6.98757 5.92263 6.81527 6.22933 6.50857 6.53603 6.20187 6.70833 5.78589 6.70833 5.35215 6.70833 4.91841 6.53603 4.50244 6.22933 4.19574 5.92263 3.88904 5.50666 3.71674 5.07292 3.71674z"
      fill="currentColor" fillRule="evenodd" clipRule="evenodd" />
    <path d="M3.00059 6.87125C3.21556 6.51719 3.67685 6.40443 4.03091 6.61939L17.3892 14.7298C17.7433 14.9448 17.8561 15.4061 17.6411 15.7601C17.4261 16.1142 16.9648 16.227 16.6108 16.012L3.25245 7.90158C2.89838 7.68661 2.78562 7.22532 3.00059 6.87125Z"
      fill="currentColor" fillRule="evenodd" clipRule="evenodd" />
    <path d="M17.6411 4.48582C17.8561 4.83988 17.7433 5.30117 17.3892 5.51614L4.03091 13.6266C3.67685 13.8415 3.21556 13.7288 3.00059 13.3747C2.78562 13.0206 2.89838 12.5593 3.25245 12.3444L16.6108 4.23396C16.9648 4.01899 17.4261 4.13175 17.6411 4.48582Z"
      fill="currentColor" fillRule="evenodd" clipRule="evenodd" />
  </svg>
);
Cut.displayName = 'Cut';
export default Cut;
/* tslint:enable */
/* eslint-enable */
