import { FC, useState, useRef } from 'react';
import { Icon, Avatar, useClickOutside, DialogContentContainer } from 'monday-ui-react-core';
import {
  PersonRound,
  Notifications as NotificationsIcon,
  LogOut,
} from 'monday-ui-react-core/icons';
import { Notifications } from '..';
import { ReactComponent as HeaderShapes } from '../../assets/shapes/header-shapes.svg';
import { ReactComponent as NewNotificationsPoint } from '../../assets/new-notifications-point.svg';
import { hasNewNotifications } from '../../services';
import { appStore } from '../../stores';

import styles from './Header.module.scss';

export const Header: FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);
  const modalRef = useRef<HTMLDivElement | null>(null);
  const notificationModalRef = useRef<HTMLDivElement | null>(null);
  const { user, notificationList, settingsLabelIds, selectedItem } = appStore;

  const photoThumb = user?.photoThumbSmall;

  function onModalOpen() {
    setIsModalOpen(true);
  }

  function onCloseClick() {
    //setTimeout is used to ensure it is called after onOpenClick function
    setTimeout(() => {
      setIsModalOpen(false);
    });
  }

  function onSignOutClick() {
    appStore.logout();
  }

  function onNotificationModalOpen() {
    setIsNotificationModalOpen(true);
  }
  function onNotificationModalClose() {
    // It's hack:
    // is selectedItem is not null, it means that ItemModal is open
    // and we should not close sidebar but close ItemModal instead
    if (!selectedItem) {
      //setTimeout is used to ensure it is called after onOpenClick function
      setTimeout(() => {
        setIsNotificationModalOpen(false);
      });
    }
  }

  useClickOutside({
    ref: modalRef,
    callback: onCloseClick,
  });

  useClickOutside({
    ref: notificationModalRef,
    callback: onNotificationModalClose,
  });

  const areNewNotifications = hasNewNotifications(notificationList, settingsLabelIds);

  return (
    <div className={styles.headerWrapper}>
      <header className={styles.header}>
        <div className={styles.notifications}>
          <div className={styles.iconWrapper} onClick={onNotificationModalOpen}>
            <div className={styles.pointWrapper}>
              {areNewNotifications && <NewNotificationsPoint />}
            </div>

            <Icon
              className={styles.notifications}
              ignoreFocusStyle
              icon={NotificationsIcon}
              iconSize={24}
            />
          </div>
        </div>

        <div className={styles.avatarWrapper} ref={modalRef}>
          <div onClick={onModalOpen}>
            {photoThumb ? (
              <Avatar
                ariaLabel={user.name}
                size={Avatar.sizes.MEDIUM}
                src={photoThumb}
                type={Avatar.types.IMG}
                withoutTooltip={isModalOpen || isNotificationModalOpen}
              />
            ) : (
              <Icon ignoreFocusStyle iconType={Icon.type.SRC} icon={PersonRound} iconSize={36} />
            )}
          </div>

          {isModalOpen && (
            <DialogContentContainer className={styles.container}>
              <div className={styles.signOut} onClick={onSignOutClick}>
                <Icon ignoreFocusStyle iconType={Icon.type.SRC} icon={LogOut} />
                <span>Sign Out</span>
              </div>
            </DialogContentContainer>
          )}
        </div>

        <div className={styles.shape}>
          <HeaderShapes />
        </div>
      </header>
      {isNotificationModalOpen && (
        <div ref={notificationModalRef} className={styles.notificationContainer}>
          <Notifications />
        </div>
      )}
    </div>
  );
};
