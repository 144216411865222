import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { MobileScreen, Header, Sidebar, Main, ItemModal, PreScreen } from '..';
import { appStore } from '../../stores';
import { Loader } from 'monday-ui-react-core';
import { useDisableScroll } from '../../hooks';

import 'monday-ui-react-core/dist/main.css';
import styles from './App.module.scss';

export const App = observer(() => {
  const { disableScroll, enableScroll } = useDisableScroll('#app');

  useEffect(() => {
    if (appStore.selectedItemId) {
      disableScroll();
      return;
    }
    enableScroll();
  }, [appStore.selectedItemId]);

  useEffect(() => {
    (async () => {
      await appStore.init();
    })();
  }, []);

  if (appStore.error) {
    return (
      <div className={styles.center}>
        <h5 className={styles.error}> {appStore.error}</h5>
      </div>
    );
  }

  if (appStore.loading) {
    return (
      <div className={styles.center}>
        <Loader size={Loader.sizes.LARGE} />
      </div>
    );
  }

  if (!appStore.isDesigner) {
    return <PreScreen />;
  }

  if (!appStore.email) {
    return <PreScreen isLogin />;
  }

  return (
    <div className={styles.app} id="app">
      <div className={styles.desktop}>
        <Sidebar />
        <div className={styles.content}>
          <Header />
          <Main />
        </div>
      </div>
      {appStore.selectedItem && (
        <div className={styles.modalBg}>
          <ItemModal />
        </div>
      )}
      <div className={styles.mobile}>
        <MobileScreen />
      </div>
    </div>
  );
});
