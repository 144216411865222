import { EventType, ItemHistory, NotificationData, Tool, Update, User } from '../types';
import { LABEL_NAMES, UPDATE_NOTIFICATION_TITLE } from '../constants';
import { getFormattedDate } from '../utils';

export function getNotificationList({
  tools,
  updates,
  itemsHistory,
  users,
  lastReadDate,
}: {
  tools: Tool[];
  updates: Update[];
  itemsHistory: Record<string, ItemHistory[]>;
  users: User[];
  lastReadDate: string;
}) {
  tools = tools.filter(tool => tool.status.text !== LABEL_NAMES.ACTIVE);

  const toolsNotifications = tools.reduce((acc, tool) => {
    const toolHistories = itemsHistory[tool.id];
    const filteredToolHistories = toolHistories.filter(history => {
      const isItemUpdate = history.event === EventType.Item_updated;

      const isStatusChanged =
        history.event === EventType.Updated_status &&
        (history.label === LABEL_NAMES.PUBLISHED || history.label === LABEL_NAMES.UPDATED);

      return isItemUpdate || isStatusChanged;
    });

    const itemNotifications = filteredToolHistories.map(toolHistory => {
      const user = users.find(user => user.id === Number(toolHistory.userId));
      const updateDescription =
        toolHistory.event === EventType.Item_updated ? toolHistory.message : '';
      const isItemPublish =
        toolHistory.event === EventType.Updated_status &&
        toolHistory.label === LABEL_NAMES.PUBLISHED;

      const result = {
        name: tool.title,
        createdAt: toolHistory.createdAt,

        itemId: tool.id,
        labels: tool.profession,
        isRead: isNotificationRead(toolHistory.createdAt, lastReadDate),
        updateDescription,
        imgSrc: tool.smallImgSrc,
        isTool: true,
        date: getFormattedDate({ date: toolHistory.createdAt }),
        isItemPublish,
        event: getItemEvent(isItemPublish),
        description: updateDescription,
        userName: user?.name,
        relatedResources: [],
      };

      return result;
    });

    acc.push(...itemNotifications);
    return acc;
  }, [] as NotificationData[]);

  const updateImgSrc = updates.find(update => update.smallImgSrc)?.smallImgSrc || '';

  const updatesNotifications = updates.reduce((acc, update) => {
    const updateHistories = itemsHistory[update.id];
    const filteredUpdateHistories = updateHistories.filter(history => {
      return history.event === EventType.Updated_status && history.label === LABEL_NAMES.PUBLISHED;
    });

    const itemNotifications = filteredUpdateHistories.map(updateHistory => {
      const result = {
        name: UPDATE_NOTIFICATION_TITLE,
        createdAt: updateHistory.createdAt,
        itemId: update.id,
        labels: update.profession,
        isRead: isNotificationRead(updateHistory.createdAt, lastReadDate),
        imgSrc: updateImgSrc,
        isTool: false,
        date: getFormattedDate({ date: updateHistory.createdAt }),
        isItemPublish:
          updateHistory.event === EventType.Updated_status &&
          updateHistory.label === LABEL_NAMES.PUBLISHED,
        link: update.linkUrl,
        event: 'has been added. Check it out!',
      };

      return result;
    });

    acc.push(...itemNotifications);

    return acc;
  }, [] as NotificationData[]);

  return [...toolsNotifications, ...updatesNotifications].sort(
    (x, y) => new Date(y.createdAt).getTime() - new Date(x.createdAt).getTime()
  );
}

export function findLastTwoNotifications(notifications: NotificationData[], id: string) {
  return notifications.filter(notification => notification.itemId === id).slice(0, 2);
}

function getItemEvent(isPublish: boolean) {
  if (isPublish) {
    return 'has been added.';
  }

  return 'has been updated.';
}

function isNotificationRead(createdAt: string, lastReadDate: string) {
  if (!lastReadDate) return false;

  return lastReadDate > createdAt;
}

export function hasNewNotifications(notifications: NotificationData[], labelIds: string[] | null) {
  if (!labelIds) {
    return notifications.some(notification => !notification.isRead);
  }

  return notifications
    .filter(notification => notification.labels.some(label => labelIds.includes(String(label.id))))
    .some(notification => !notification.isRead);
}
