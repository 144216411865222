import { GalleryView, EmptyToolsScreen, ListView, SearchBar, Filter } from '../../ui-kit';
import { useMemo, useState } from 'react';
import cn from 'classnames';
import { ReactComponent as DottedLine } from '../../assets/shapes/tools-dotted-line.svg';
import { ReactComponent as List } from '../../assets/list-icon.svg';
import { ReactComponent as Table } from '../../assets/table-icon.svg';
import { appStore } from '../../stores';
import { observer } from 'mobx-react-lite';
import { COLUMN_NAMES, GENERAL_OPTION_ID } from '../../constants';
import { getFilteredTools, isFilterApplied } from '../../services';
import { getShuffledItems } from '../../utils';
import styles from './Tools.module.scss';
import sharedStyles from '../../shared.module.scss';

export const Tools = observer(() => {
  const { structure, getStatusOptions, getTagsOptions } = appStore;
  const { tools } = structure;

  const resourceTypeOption = getStatusOptions(COLUMN_NAMES.RESOURCE_TYPE);
  const platformOptions = getStatusOptions(COLUMN_NAMES.PLATFORM);
  const professionOptions = getTagsOptions();

  const [isListView, setIsListView] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({
    resourceType: resourceTypeOption,
    professions: professionOptions,
    platforms: platformOptions,
    searchValue: '',
  });

  const shuffledItems = getShuffledItems(tools);

  const filteredTools = useMemo(() => {
    return getFilteredTools(shuffledItems, selectedFilters);
  }, [selectedFilters, shuffledItems]);

  const isFilterActive = useMemo(() => {
    return isFilterApplied(selectedFilters);
  }, [selectedFilters]);

  function onSearchChange(text: string) {
    setSelectedFilters(prev => ({ ...prev, searchValue: text }));
  }

  function onOptionChange(
    id: string,
    key: 'professions' | 'professions' | 'platforms',
    optionsValue: boolean
  ) {
    setSelectedFilters(prev => {
      const updatedOptions =
        id === GENERAL_OPTION_ID
          ? prev[key].map(option => {
              return { ...option, value: optionsValue };
            })
          : prev[key].map(option => {
              return { ...option, value: option.id === id ? !option.value : option.value };
            });

      return {
        ...prev,
        [key]: updatedOptions,
      };
    });
  }

  function viewToggle() {
    setIsListView(!isListView);
  }

  const isListEmpty = !filteredTools.length;
  const currentView = isListView ? (
    <ListView items={filteredTools} />
  ) : (
    <GalleryView items={filteredTools} />
  );

  return (
    <div className={styles.tools}>
      <h1 className={styles.title}>Design resources & tools</h1>
      <div className={styles.content}>
        <div className={styles.search}>
          <SearchBar
            value={selectedFilters.searchValue}
            onChange={onSearchChange}
            debounceRate={500}
          />
        </div>

        <div className={styles.filtersWrapper}>
          <span className={styles.label}>Filter by:</span>
          <div className={styles.filters}>
            <Filter
              options={selectedFilters.resourceType}
              title="All resource types"
              onOptionChange={onOptionChange}
              valueKey="resourceType"
            />
            <Filter
              options={selectedFilters.professions}
              title="All professions"
              onOptionChange={onOptionChange}
              valueKey="professions"
            />
            <Filter
              valueKey="platforms"
              options={selectedFilters.platforms}
              title="All platforms"
              onOptionChange={onOptionChange}
            />
          </div>
          <div className={cn(styles.icons, sharedStyles.icon)} onClick={viewToggle}>
            {isListView ? <Table /> : <List />}
          </div>
        </div>

        <div className={styles.list}>
          <div className={styles.shape}>
            <DottedLine />
          </div>
          {isListEmpty ? <EmptyToolsScreen withFilters={isFilterActive} /> : currentView}
        </div>
      </div>
    </div>
  );
});
