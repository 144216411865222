export interface ToolProps {
  imgSrc: string;
  labels: { title: string; isNew?: boolean }[];
  title: string;
  description: string;
  person: {
    name: string;
    photoThumb: string;
  };
  date: string;
}

export interface ColumnValue {
  id: string;
  text: string;
  type: string;
  title: string;
  value: string | null;
}

export interface Item {
  name: string;
  id: string;
  columnValues: ColumnValue[];
  updates: UpdateData[];
  createdAt: string;
  creatorId: string;
  group: {
    id: string;
    title: string;
  };
}
export interface Label {
  text: string;
  id: string;
  columnId: string;
}

export interface User {
  id: number;
  email: string;
  photoThumbSmall: string;
  name: string;
}

export interface Column {
  id: string;
  title: string;
  settingsStr: string;
}

export type Tag = { color: string; id: number; name: string };

export interface Structure {
  plugins: Plugin[];
  updates: Update[];
  tools: Tool[];
  unsupportedGroup: Item[];
}

export interface Option {
  id: string | number;
  title: string;
  value: boolean;
}

interface Plugin {
  id: string;
  label: string;
  linkUrl: string;
  status: string | null;
  title: string;
  user?: User;
  description: string;
}

export interface Update {
  id: string;
  linkUrl: string;
  status: string | null;
  title: string;
  profession: Tag[];
  smallImgSrc: string;
}

export interface Tool {
  id: string;
  imgSrc: string;
  label: string;
  linkUrl: string;
  status: Label;
  title: string;
  smallImgSrc: string;
  user?: User;
  description: string;
  isFavorite: boolean;
  resourcesType: Label;
  profession: Tag[];
  platform: Label;
  isNew: boolean;
  moreInfo: string;
  relatedResourcesIds: number[];
  eventTitle: string;
  date: string;
}

export interface ActivityLog {
  createdAt: string;
  data: string;
  event: string;
  id: string;
  userId: string;
}

export interface ActivityLogData {
  boardId: string;
  columnId: string;
  columnTitle: string;
  columnType: string;
  groupId: string;
  isColumnWithHidePermissions: boolean;
  isTopGroup: boolean;
  previousValue: { label: Label };
  pulseId: string;
  pulseName: string;
  value: { label: Label };
}

export interface UpdateData {
  id: string;
  body: string;
  createdAt: string;
  creator: { id: string };
  textBody: string;
}

export interface ActivityLogInfo {
  createdAt: string;
  event: EventType.Updated_status;
  userId: string;
  label: string;
}

export enum EventType {
  Item_created = 'item_created',
  Item_updated = 'item_updated',
  Updated_status = 'update_column_value',
}

export type ItemHistory =
  | {
      createdAt: string;
      event: EventType.Item_created;
      userId: string;
    }
  | {
      createdAt: string;
      event: EventType.Item_updated;
      userId: string | number;
      message: string;
    }
  | ActivityLogInfo;

export interface NotificationData {
  name: string;
  createdAt: string;
  itemId: string;
  labels: Tag[];
  isRead: boolean;
  description?: string;
  isTool: boolean;
  isItemPublish: boolean;
  imgSrc: string;
  date: string;
  link?: string;
  userName?: string;
  event: string;
}
