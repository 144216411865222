/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface TurnIntoProps extends React.SVGAttributes<SVGElement> {
size?: string | number;
}
const TurnInto: React.FC<TurnIntoProps> = ({size, ...props}) => (
  <svg viewBox="0 0 20 20" fill="currentColor" width={ size || "20" } height={ size || "20" } {...props}>
    <path d="M6.85001 4.79999C6.85001 4.38577 7.18579 4.04999 7.60001 4.04999H13.1C13.8294 4.04999 14.5288 4.33972 15.0445 4.85544 15.5603 5.37117 15.85 6.07064 15.85 6.79999V11.3C15.85 11.7142 15.5142 12.05 15.1 12.05 14.6858 12.05 14.35 11.7142 14.35 11.3V6.79999C14.35 6.46847 14.2183 6.15052 13.9839 5.9161 13.7495 5.68168 13.4315 5.54999 13.1 5.54999H7.60001C7.18579 5.54999 6.85001 5.2142 6.85001 4.79999zM5.10001 8.54999C5.51422 8.54999 5.85001 8.88577 5.85001 9.29999V13.8C5.85001 14.1315 5.9817 14.4495 6.21612 14.6839 6.45054 14.9183 6.76849 15.05 7.10001 15.05H12.6C13.0142 15.05 13.35 15.3858 13.35 15.8 13.35 16.2142 13.0142 16.55 12.6 16.55H7.10001C6.37066 16.55 5.67119 16.2603 5.15546 15.7445 4.63974 15.2288 4.35001 14.5293 4.35001 13.8V9.29999C4.35001 8.88577 4.68579 8.54999 5.10001 8.54999z"
      fill="currentColor" fillRule="evenodd" clipRule="evenodd" />
    <path d="M12.0696 8.26764C12.3625 7.97475 12.8374 7.97475 13.1303 8.26764L15.1 10.2373 17.0696 8.26764C17.3625 7.97475 17.8374 7.97475 18.1303 8.26764 18.4232 8.56054 18.4232 9.03541 18.1303 9.3283L15.6303 11.8283C15.3374 12.1212 14.8625 12.1212 14.5696 11.8283L12.0696 9.3283C11.7768 9.03541 11.7768 8.56054 12.0696 8.26764zM4.56968 8.76764C4.86257 8.47475 5.33744 8.47475 5.63034 8.76764L8.13034 11.2676C8.42323 11.5605 8.42323 12.0354 8.13034 12.3283 7.83744 12.6212 7.36257 12.6212 7.06968 12.3283L5.10001 10.3586 3.13034 12.3283C2.83744 12.6212 2.36257 12.6212 2.06968 12.3283 1.77678 12.0354 1.77678 11.5605 2.06968 11.2676L4.56968 8.76764z"
      fill="currentColor" fillRule="evenodd" clipRule="evenodd" />
  </svg>
);
TurnInto.displayName = 'TurnInto';
export default TurnInto;
/* tslint:enable */
/* eslint-enable */
