import { FC, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { Close } from 'monday-ui-react-core/dist/allIcons';
import { Icon, useClickOutside } from 'monday-ui-react-core';
import { Avatar } from 'monday-ui-react-core';
import cn from 'classnames';
import { ReactComponent as FavoriteIcon } from '../../assets/favorite-icon.svg';
import { ReactComponent as ExternalLink } from '../../assets/external-link-icon.svg';
import ownerIcon from '../../assets/owner-icon.svg';
import { Label, Navigation } from '../../ui-kit';
import { appStore } from '../../stores';
import sharedStyles from '../../shared.module.scss';
import { findLastTwoNotifications } from '../../services';
import styles from './ItemModal.module.scss';

export const ItemModal: FC = observer(() => {
  const modalRef = useRef<HTMLDivElement | null>(null);
  const {
    updateFavorites,
    selectedItem,
    setSelectedItemId,
    notificationList,
    getRelatedResources,
  } = appStore;

  if (!selectedItem) return <></>;

  const lastNotifications = findLastTwoNotifications(notificationList, selectedItem?.id);

  function onClose() {
    setSelectedItemId(null);
  }

  useClickOutside({
    ref: modalRef,
    callback: onClose,
  });

  const relatedResources = getRelatedResources(selectedItem.relatedResourcesIds);

  return (
    <div ref={modalRef} className={styles.modal}>
      <div className={styles.topBox}>
        <Icon
          iconType={Icon.type.SVG}
          clickable
          ignoreFocusStyle
          onClick={onClose}
          icon={Close}
          className={styles.close}
          iconSize={14}
        />
        <img src={selectedItem.smallImgSrc} className={styles.image} />

        <div className={styles.column}>
          <div>
            <div className={styles.labels}>
              <h4 className={styles.title}>{selectedItem.title}</h4>
              <div className={styles.labels}>
                <Label kind="line" title={selectedItem.resourcesType.text} />
                {selectedItem.isNew && <Label primary title="New" />}
              </div>
            </div>
            <span className={styles.description}>{selectedItem.description}</span>
          </div>
          <div className={styles.icons}>
            <div
              className={cn(
                sharedStyles.icon,
                sharedStyles.favIcon,
                selectedItem.isFavorite && sharedStyles.iconActive
              )}
              onClick={() => updateFavorites(selectedItem.id)}
            >
              <FavoriteIcon />
            </div>
            <a href={selectedItem.linkUrl} className={sharedStyles.icon} target="_blank">
              <ExternalLink />
            </a>
          </div>
        </div>
      </div>

      <div className={styles.line} />

      <div className={styles.bottomBox}>
        <div className={styles.updates}>
          <div className={styles.notifications}>
            {lastNotifications.length ? (
              lastNotifications.map(notification => (
                <div key={notification.createdAt} className={styles.notification}>
                  <span className={styles.text}>
                    <span className={styles.title}>{notification.name}</span> {notification.event}
                    <br /> {notification.description}
                  </span>
                  <div className={styles.metadata}>
                    <span>{notification.date}</span>
                    <div className={styles.point}></div>
                    <span>{notification.userName}</span>
                  </div>
                </div>
              ))
            ) : (
              <span className={styles.noNotice}>
                There are no updates about this
                <br /> resource yet.
              </span>
            )}
          </div>
          <div className={styles.line} />

          <div className={styles.inline}>
            <Avatar
              className={styles.avatar}
              size={Avatar.sizes.MEDIUM}
              src={selectedItem?.user?.photoThumbSmall}
              type={Avatar.types.IMG}
              bottomLeftBadgeProps={{ src: ownerIcon }}
            />
            <div className={styles.owner}>{selectedItem.user?.name}</div>
          </div>
        </div>
        <div className={styles.resources}>
          <div>
            <span className={styles.title}>Related resources</span>
            {relatedResources.length ? (
              <div className={styles.links}>
                {relatedResources.map(resource => (
                  <Navigation
                    text={resource.name}
                    key={resource.id}
                    onClick={() => setSelectedItemId(String(resource.id))}
                  />
                ))}
              </div>
            ) : (
              <div className={styles.description}>
                If there will be a related resources,
                <br /> you’ll see it here.
              </div>
            )}
          </div>
          {selectedItem.moreInfo && (
            <div>
              <span className={styles.title}>More info</span>
              <div className={styles.text}>{selectedItem.moreInfo}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
});
