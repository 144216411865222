import { FC } from 'react';
import { ReactComponent as NoNotificationsLogo } from '../../assets/no-notifications-logo.svg';
import { NotificationData } from '../../types';
import { appStore } from '../../stores';
import cn from 'classnames';
import styles from './NotificationsList.module.scss';

export const NotificationsList: FC<{ items: NotificationData[] }> = ({ items }) => {
  const { setSelectedItemId } = appStore;
  function onUpdateClick(linkUrl?: string) {
    window.open(linkUrl, '_blank');
  }

  function onToolClick(id: string) {
    setSelectedItemId(id);
  }

  return (
    <div>
      {items.length ? (
        <div className={styles.list}>
          {items.map(notification => {
            const onClickAction = notification.isTool
              ? () => onToolClick(notification.itemId)
              : () => onUpdateClick(notification.link);

            return (
              <div
                key={notification.createdAt}
                className={cn(styles.item, !notification.isRead && styles.unread)}
                onClick={onClickAction}
              >
                <img className={styles.image} alt="img" src={notification.imgSrc} />
                <div className={styles.column}>
                  <span className={styles.text}>
                    <span className={styles.title}>{notification.name}</span> {notification.event}
                    <br /> {notification.description}
                  </span>
                  <div className={styles.metadata}>
                    <span>{notification.date}</span>

                    {notification.labels.map(label => {
                      return (
                        <div key={label.id} className={styles.labelContainer}>
                          <div className={styles.point}></div>
                          {label.name}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className={styles.noNotifications}>
          <div>
            <NoNotificationsLogo />
          </div>
          <span className={styles.text}>No notifications yet!</span>
          <span className={styles.description}>
            Once your updates start rolling in, you can find them here.
          </span>
        </div>
      )}
    </div>
  );
};
