/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface BoardShareableProps extends React.SVGAttributes<SVGElement> {
size?: string | number;
}
const BoardShareable: React.FC<BoardShareableProps> = ({size, ...props}) => (
  <svg viewBox="0 0 20 20" fill="currentColor" width={ size || "20" } height={ size || "20" } {...props}>
    <path d="M7.5 4.5H16C16.2761 4.5 16.5 4.72386 16.5 5V7.88998C16.7908 7.83097 17.0917 7.79999 17.3999 7.79999C17.6034 7.79999 17.8037 7.81349 18 7.83965V5C18 3.89543 17.1046 3 16 3H4C2.89543 3 2 3.89543 2 5V15C2 16.1046 2.89543 17 4 17H9.34145C9.06468 16.5554 8.88215 16.0461 8.82178 15.5H7.5L7.5 4.5ZM6 4.5H4C3.72386 4.5 3.5 4.72386 3.5 5V15C3.5 15.2761 3.72386 15.5 4 15.5H6L6 4.5ZM15.4464 11.9979C15.4173 11.8516 15.4023 11.7017 15.4023 11.5502C15.4023 10.9406 15.6445 10.3558 16.0757 9.92472C16.5068 9.4936 17.0915 9.2514 17.7012 9.2514C18.3109 9.2514 18.8956 9.4936 19.3267 9.92472C19.7578 10.3558 20 10.9406 20 11.5502C20 12.1599 19.7578 12.7447 19.3267 13.1758C18.8956 13.6069 18.3109 13.8491 17.7012 13.8491C17.1494 13.8491 16.6182 13.6507 16.2027 13.2935L14.5247 14.309C14.5728 14.4951 14.5977 14.6881 14.5977 14.8836C14.5977 15.0813 14.5722 15.2765 14.5231 15.4646L16.016 16.0786C16.0355 16.0577 16.0554 16.0371 16.0757 16.0168C16.5068 15.5856 17.0915 15.3434 17.7012 15.3434C18.3109 15.3434 18.8956 15.5856 19.3267 16.0168C19.7578 16.4479 20 17.0326 20 17.6423C20 18.252 19.7578 18.8367 19.3267 19.2678C18.8956 19.6989 18.3109 19.9411 17.7012 19.9411C17.0915 19.9411 16.5068 19.6989 16.0757 19.2678C15.6445 18.8367 15.4023 18.252 15.4023 17.6423C15.4023 17.5783 15.405 17.5147 15.4103 17.4514L13.664 16.7332C13.2711 17.0232 12.7932 17.1824 12.2988 17.1824C11.6892 17.1824 11.1044 16.9402 10.6733 16.5091C10.2422 16.078 10 15.4933 10 14.8836C10 14.2739 10.2422 13.6891 10.6733 13.258C11.1044 12.8269 11.6892 12.5847 12.2988 12.5847C12.8064 12.5847 13.2967 12.7526 13.6955 13.0576L15.4464 11.9979Z"
      fill="currentColor" fillRule="evenodd" clipRule="evenodd" />
  </svg>
);
BoardShareable.displayName = 'BoardShareable';
export default BoardShareable;
/* tslint:enable */
/* eslint-enable */
