import { FC, useMemo } from 'react';
import { Checkbox } from 'monday-ui-react-core';
import cn from 'classnames';
import { GENERAL_OPTION_ID } from '../../constants';
import styles from './CheckboxesList.module.scss';

interface CheckboxesListProps {
  options: { title: string; value: boolean; id: string }[];
  onOptionChange(id: string, key?: string, optionsValue?: boolean): void;
  withColumn?: boolean;
  valueKey?: string;
  generalOption?: string;
}

export const CheckboxesList: FC<CheckboxesListProps> = ({
  options,
  onOptionChange,
  withColumn = false,
  valueKey,
  generalOption,
}) => {
  const generalValue = useMemo(() => {
    return options.every(option => option.value);
  }, [options]);

  function onOptionClick(id: string) {
    onOptionChange(id, valueKey);
  }

  function onGeneralOptionClick() {
    onOptionChange(GENERAL_OPTION_ID, valueKey, !generalValue);
  }

  const checkboxesInColumn = options.length >= 5 ? 6 : options.length + 1;

  return (
    <div
      className={cn(styles.wrapper, withColumn && styles.column)}
      style={{ gridTemplateRows: `repeat(${checkboxesInColumn}, 1fr)` }}
    >
      {generalOption && (
        <Checkbox
          labelClassName={cn(styles.label, generalValue && styles.checkedLabel)}
          checked={generalValue}
          key={generalOption}
          label={generalOption}
          onChange={onGeneralOptionClick}
          className={styles.checkbox}
        />
      )}
      {options.map(option => {
        return (
          <Checkbox
            labelClassName={cn(styles.label, option.value && styles.checkedLabel)}
            checked={option.value}
            key={option.id}
            label={option.title}
            onChange={() => onOptionClick(option.id)}
            className={styles.checkbox}
          />
        );
      })}
    </div>
  );
};
