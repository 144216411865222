/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface RadioProps extends React.SVGAttributes<SVGElement> {
size?: string | number;
}
const Radio: React.FC<RadioProps> = ({size, ...props}) => (
  <svg viewBox="0 0 20 20" fill="currentColor" width={ size || "20" } height={ size || "20" } {...props}>
    <path d="M10 16.5C6.41015 16.5 3.5 13.5899 3.5 10C3.5 6.41015 6.41015 3.5 10 3.5C13.5899 3.5 16.5 6.41015 16.5 10C16.5 13.5899 13.5899 16.5 10 16.5ZM2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10ZM10 13C11.6569 13 13 11.6569 13 10C13 8.34315 11.6569 7 10 7C8.34315 7 7 8.34315 7 10C7 11.6569 8.34315 13 10 13Z"
      fill="currentColor" fillRule="evenodd" clipRule="evenodd" />
  </svg>
);
Radio.displayName = 'Radio';
export default Radio;
/* tslint:enable */
/* eslint-enable */
