import { FC } from 'react';
import { ReactComponent as MobileMondayLogo } from '../../assets/mobile-monday-logo.svg';
import { ReactComponent as MobileScreenLogo } from '../../assets/mobile-screen-logo.svg';
import styles from './MobileScreen.module.scss';

export const MobileScreen: FC = () => {
  return (
    <div className={styles.wrapper}>
      <div>
        <MobileMondayLogo />
      </div>
      <div className={styles.center}>
        <MobileScreenLogo />
        <h2 className={styles.title}>We love thinking big!</h2>
        <span className={styles.text}>
          This web experience is not available for mobile browsing. Switch to desktop for access.
        </span>
      </div>
    </div>
  );
};
