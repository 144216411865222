/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface SecurityProps extends React.SVGAttributes<SVGElement> {
size?: string | number;
}
const Security: React.FC<SecurityProps> = ({size, ...props}) => (
  <svg viewBox="0 0 20 20" fill="currentColor" width={ size || "20" } height={ size || "20" } {...props}>
    <path d="M10.4114 17.9213C10.1387 18.026 9.83594 18.026 9.56321 17.9213L8.80471 17.6324C6.88623 16.8981 5.23825 15.6095 4.07729 13.936C2.91632 12.2624 2.2967 10.2823 2.29982 8.2556V5.32902C2.29982 5.02513 2.56878 4.79266 2.87192 4.81402C4.13696 4.90315 5.40854 4.70016 6.58415 4.21787C7.72219 3.75099 8.7348 3.03693 9.54812 2.13252C9.77572 1.87943 10.1876 1.888 10.4072 2.14805C11.9378 3.96046 14.2426 4.85746 17.1405 4.77877C17.4296 4.77092 17.6748 4.99878 17.6748 5.28798V8.2556C17.6779 10.2823 17.0583 12.2624 15.8973 13.936C14.7364 15.6095 13.0884 16.8981 11.1699 17.6324L10.4114 17.9213ZM9.34094 16.2315L9.33968 16.2311C7.70085 15.6035 6.29698 14.504 5.30976 13.081C4.3225 11.6578 3.79717 9.97654 3.79982 8.25791V8.2556V6.3283C4.94762 6.28751 6.08384 6.04444 7.15347 5.60563C8.1867 5.18175 9.13368 4.5851 9.9544 3.84535C11.5851 5.36934 13.7291 6.1568 16.1748 6.26937L16.1748 8.2556L16.1748 8.25791C16.1774 9.97654 15.6521 11.6578 14.6649 13.081C13.6777 14.504 12.2739 15.6034 10.6351 16.231L10.6337 16.2315L9.98731 16.4777L9.34094 16.2315ZM12.9409 9.21234C13.1964 8.88629 13.1392 8.41487 12.8131 8.15941C12.487 7.90395 12.0156 7.96118 11.7602 8.28724L9.83812 10.7404L8.9161 10.1258C8.57146 9.89599 8.10581 9.98912 7.87604 10.3338C7.64628 10.6784 7.73941 11.1441 8.08405 11.3738L9.58405 12.3738C9.91018 12.5912 10.3487 12.5209 10.5905 12.2123L12.9409 9.21234Z"
      fill="currentColor" fillRule="evenodd" clipRule="evenodd" />
  </svg>
);
Security.displayName = 'Security';
export default Security;
/* tslint:enable */
/* eslint-enable */
