/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface CountryProps extends React.SVGAttributes<SVGElement> {
size?: string | number;
}
const Country: React.FC<CountryProps> = ({size, ...props}) => (
  <svg viewBox="0 0 20 20" fill="currentColor" width={ size || "20" } height={ size || "20" } {...props}>
    <path d="M16.7831 4.0241C16.6867 3.83133 16.494 3.73494 16.3976 3.63855C16.2048 3.54217 16.012 3.44578 15.8193 3.44578C15.6265 3.44578 15.4337 3.44578 15.241 3.44578L13.8916 3.73494C13.3133 3.92771 12.6386 3.92771 12.0602 3.83133C11.3855 3.73494 10.8072 3.54217 10.2289 3.25301C9.45783 2.96386 8.68675 2.6747 7.81928 2.57831C6.95181 2.48193 6.08434 2.57831 5.31325 2.77108L4.44578 2.96386V2.77108C4.44578 2.28916 4.15663 2 3.77108 2C3.38554 2 3 2.28916 3 2.77108V3.92771V11.253V17.2289C3 17.6145 3.28916 18 3.77108 18C4.25301 18 4.54217 17.7108 4.54217 17.2289V11.8313L5.79518 11.5422C6.46988 11.3494 7.14458 11.3494 7.81928 11.3494C8.49398 11.4458 9.07229 11.6386 9.6506 11.9277C10.3253 12.3133 11.1928 12.506 11.9639 12.6024C12.253 12.6024 12.5422 12.6024 12.7349 12.6024C13.3133 12.6024 13.7952 12.506 14.3735 12.4096L16.012 12.0241C16.3012 11.9277 16.494 11.8313 16.6867 11.6386C16.8795 11.4458 16.9759 11.1566 16.9759 10.8675V4.6988C16.9759 4.40964 16.8795 4.21687 16.7831 4.0241ZM15.5301 10.6747L13.988 11.0602C13.3133 11.253 12.7349 11.253 12.0602 11.1566C11.3855 11.0602 10.8072 10.8675 10.2289 10.5783C9.45783 10.1928 8.68675 9.90361 7.81928 9.80723C7.53012 9.80723 7.33735 9.80723 7.04819 9.80723C6.46988 9.80723 5.89157 9.90361 5.31325 10L4.44578 10.1928V4.40964L5.6988 4.12048C6.27711 4.0241 6.95181 3.92771 7.62651 4.0241C8.3012 4.12048 8.87952 4.31325 9.45783 4.60241C10.1325 4.98795 11 5.18072 11.7711 5.27711C12.6386 5.37349 13.4096 5.27711 14.2771 5.08434L15.5301 4.79518V10.6747Z"
      fill="currentColor" />
  </svg>
);
Country.displayName = 'Country';
export default Country;
/* tslint:enable */
/* eslint-enable */
