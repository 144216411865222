import { FC, useRef, useState } from 'react';
import { useClickOutside } from 'monday-ui-react-core';
import { observer } from 'mobx-react-lite';
import cn from 'classnames';
import { ReactComponent as MondayTextLogo } from '../../assets/monday-text-logo.svg';
import { ReactComponent as FavoriteIcon } from '../../assets/favorite-icon.svg';
import { ReactComponent as SmallMondayLogo } from '../../assets/small-monday-logo.svg';
import { ReactComponent as ChevronLeft } from '../../assets/chevron-left-icon.svg';
import { FavoritesList } from '../FavoritesList';
import { appStore } from '../../stores';
import sharedStyles from '../../shared.module.scss';
import styles from './Sidebar.module.scss';

export const Sidebar: FC = observer(() => {
  const modalRef = useRef<HTMLDivElement | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { selectedItem } = appStore;

  function onToggle() {
    setIsModalOpen(!isModalOpen);
  }

  function onClose() {
    // It's hack:
    // is selectedItem is not null, it means that ItemModal is open
    // and we should not close sidebar but close ItemModal instead
    if (!selectedItem) {
      setIsModalOpen(false);
    }
  }

  useClickOutside({
    ref: modalRef,
    callback: onClose,
  });

  return (
    <div ref={modalRef} className={cn(styles.sidebar, isModalOpen && styles.open)}>
      <div className={styles.logos}>
        <div className={styles.mondayLogo}>
          <SmallMondayLogo />
          <div className={styles.mondayLogoText}>
            <MondayTextLogo />
          </div>
          <div className={styles.chevron} onClick={onClose}>
            <ChevronLeft />
          </div>
        </div>

        <div className={styles.favoritesWrapper}>
          <div className={cn(sharedStyles.icon, styles.favIcon)} onClick={onToggle}>
            <FavoriteIcon />
          </div>
          <span className={styles.favoritesText}>My favorites</span>
        </div>
      </div>
      <div className={styles.content}>
        <FavoritesList />
      </div>
    </div>
  );
});
