import { FC, Fragment } from 'react';
import { ReactComponent as NoFavoritesLogo } from '../../assets/no-favorites-logo.svg';
import { Delete } from 'monday-ui-react-core/dist/allIcons';
import { appStore } from '../../stores';
import { Icon } from 'monday-ui-react-core';
import { observer } from 'mobx-react-lite';
import styles from './FavoritesList.module.scss';

export const FavoritesList: FC = observer(() => {
  const { favorites, updateFavorites, setSelectedItemId } = appStore;

  function handleDeleteClick(e: MouseEvent, id: string) {
    e.stopPropagation();
    updateFavorites(id);
  }

  return (
    <div className={styles.favorites}>
      {favorites.length ? (
        <div className={styles.list}>
          {favorites.map(favorite => {
            const labels = [
              favorite.resourcesType.text,
              ...favorite.profession.map(prof => prof.name),
            ];
            return (
              <div
                key={favorite.id}
                className={styles.item}
                onClick={() => setSelectedItemId(favorite.id)}
              >
                <img className={styles.image} alt="img" src={favorite.smallImgSrc} />

                <div className={styles.wrapper}>
                  <span className={styles.title}>{favorite.title}</span>
                  <span className={styles.labels}>
                    {labels.map((label, i) => {
                      return (
                        <Fragment key={i}>
                          {i !== 0 && <div className={styles.point}></div>}
                          {label}
                        </Fragment>
                      );
                    })}
                  </span>
                </div>

                <Icon
                  iconType={Icon.type.SVG}
                  clickable
                  ignoreFocusStyle
                  onClick={(e: MouseEvent) => handleDeleteClick(e, favorite.id)}
                  icon={Delete}
                  iconSize={24}
                  className={styles.icon}
                />
              </div>
            );
          })}
        </div>
      ) : (
        <div className={styles.noFavorites}>
          <div>
            <NoFavoritesLogo />
          </div>
          <span className={styles.title}>No favorites yet!</span>
          <span className={styles.description}>
            Once you add a favorite resource, you`ll see it here.
          </span>
        </div>
      )}
    </div>
  );
});
