import { action, makeAutoObservable, observable, computed, runInAction } from 'mobx';
import {
  combineHistory,
  getNotificationList,
  getStructure,
  getUserInfo,
  setUserInfo,
  getBoardStructure,
  getItemsAssets,
  exchangeGoogleToken,
  OnUnauthorizeManager,
} from '../services';
import { Column, ItemHistory, Item, Tag, User } from '../types';
import { COLUMN_NAMES, GROUP_NAMES, SUPPORTED_DOMAINS } from '../constants';
import { extractDomainFromEmail, isPreviewMode } from '../utils';
import { TokenResponse, googleLogout } from '@react-oauth/google';
import { getUserEmail, saveUserData, removeUserData, getToken } from './storage';

const BOARD_ID = process.env.REACT_APP_BOARD_ID;

class AppStore {
  constructor() {
    makeAutoObservable(this);
    OnUnauthorizeManager.setListener(this.logout);
  }

  boardId!: number;
  columns: Column[] = [];
  users: User[] = [];
  user: User;
  items: Item[] = [];
  itemsHistory: Record<string, ItemHistory[]>;
  lastReadDate = '';
  assetsRecord: Record<string, string> = {};
  tagsRecord: Record<string, Tag> = {};
  isDraftMode = false;

  @observable email: string;
  @observable error = '';
  @observable loading = true;
  @observable favoritesIds: string[] = [];
  @observable selectedItemId: string | null = null;
  @observable settingsLabelIds: string[] | null;
  @observable isDesigner = true;

  @action
  login = async (response?: TokenResponse) => {
    try {
      if (!response) {
        runInAction(() => {
          this.error = 'Auth error';
        });
        return;
      }

      const { email, token } = await exchangeGoogleToken(response.access_token);

      if (!email) {
        runInAction(() => {
          this.error = 'Auth error';
        });
        return;
      }

      const domain = extractDomainFromEmail(email);

      if (!SUPPORTED_DOMAINS.includes(domain)) {
        runInAction(() => {
          this.isDesigner = false;
        });
        return;
      }

      saveUserData({ email, token });
      this.loading = true;
      await appStore.loadData(email);
    } catch (error) {
      runInAction(() => {
        this.error = 'Auth error';
      });
    }
  };

  @action
  logout = async () => {
    runInAction(() => {
      googleLogout();
      this.email = '';
      removeUserData();
    });
  };

  @action
  init = async () => {
    const email = getToken() ? getUserEmail() : '';

    this.email = email;
    this.loading = true;
    await appStore.loadData(email);
  };

  @action
  loadData = async (email: string) => {
    try {
      if (!email) return;

      const boardId = Number(BOARD_ID!);
      const { items, columns, users, tagsInfo } = await getBoardStructure(boardId);

      const itemsWithAssets = items.filter(item => item.group.title !== GROUP_NAMES.PLUGINS);
      const currentUser = users.find(user => user.email === email);
      const itemsHistory = await combineHistory({ boardId, columns, items });

      const assetsRecord = await getItemsAssets(
        boardId,
        itemsWithAssets.map(item => item.id)
      );

      const isDraftMode = isPreviewMode();

      const userInfo = (await getUserInfo(email)) || {};

      this.syncDbData(userInfo, items);

      runInAction(() => {
        this.loading = false;
        this.boardId = boardId;
        this.columns = columns;
        this.users = users;
        this.user = currentUser!;
        this.items = items;
        this.assetsRecord = assetsRecord;
        this.tagsRecord = tagsInfo;
        this.itemsHistory = itemsHistory;
        this.isDraftMode = isDraftMode;
        this.email = email;
      });

      runInAction(() => {
        this.updateUserInfo();
      });
    } catch (error) {
      this.handleError(error);
    } finally {
      this.loading = false;
    }
  };

  @action
  updateFavorites = (id: string) => {
    const isItemFavorite = this.favoritesIds.includes(id);

    runInAction(() => {
      this.favoritesIds = isItemFavorite
        ? this.favoritesIds.filter(item => item !== id)
        : [...this.favoritesIds, id];
    });

    this.updateUserInfo();
  };

  @action
  setSelectedItemId = (itemId: string | null) => {
    runInAction(() => {
      this.selectedItemId = itemId;
    });
  };

  @action
  updateUserInfo = async () => {
    await setUserInfo(this.user?.email, {
      favIds: this.favoritesIds,
      date: this.lastReadDate,
      settingsIds: this.settingsLabelIds,
    });
  };

  @action
  setSettingsLabelIds = (ids: string[]) => {
    runInAction(() => {
      this.settingsLabelIds = ids;
    });

    this.updateUserInfo();
  };

  @action
  syncDbData = (
    userInfo: { favIds?: string[]; readDate?: string; settingsIds?: string[] | null },
    items: Item[]
  ) => {
    const tagsInFirstGroup = [] as number[];
    const firstGroupItems = items.filter(item => GROUP_NAMES.TOOLS.includes(item.group.title));
    const updatedFavIds = userInfo?.favIds?.filter(id =>
      firstGroupItems.find(item => item.id === id)
    );

    firstGroupItems?.forEach(item => {
      const columnValues = item.columnValues;
      const professionCol = columnValues.find(
        colValue => colValue.title === COLUMN_NAMES.PROFESSION
      );
      const parsedValue = professionCol?.value && JSON.parse(professionCol.value);
      const tags = parsedValue?.tag_ids || [];
      tagsInFirstGroup.push(...tags);
    });
    const uniqueTags = [...new Set(tagsInFirstGroup)];

    const updateSettingsIds = userInfo.settingsIds
      ? userInfo.settingsIds?.filter(id => uniqueTags.includes(Number(id)))
      : null;

    runInAction(() => {
      this.lastReadDate = userInfo?.readDate || '';
      this.favoritesIds = updatedFavIds || [];
      this.settingsLabelIds = updateSettingsIds;
    });
  };

  @computed
  get selectedItem() {
    const selectedItem = this.structure.tools.find(tool => tool.id === this.selectedItemId);

    return selectedItem;
  }

  @computed
  get favorites() {
    if (!this.favoritesIds.length) return [];
    const favorites = this.structure.tools.filter(tool => tool.isFavorite);

    return favorites;
  }

  @computed
  get structure() {
    const structure = getStructure({
      items: this.items,
      users: this.users,
      assetsRecord: this.assetsRecord,
      tagsRecord: this.tagsRecord,
      favoritesIds: this.favoritesIds,
      itemsHistory: this.itemsHistory,
      isDraftMode: this.isDraftMode,
    });

    return structure;
  }

  @computed
  get notificationList() {
    const notificationList = getNotificationList({
      tools: this.structure.tools,
      itemsHistory: this.itemsHistory,
      updates: this.structure.updates,
      users: this.users,
      lastReadDate: this.lastReadDate,
    });

    return notificationList;
  }

  @computed
  get notificationsSettingsOptions() {
    const notificationsOptions = this.getTagsOptions();

    if (!this.settingsLabelIds) return notificationsOptions;

    return notificationsOptions.map(option => ({
      ...option,
      value: !!this.settingsLabelIds?.includes(option.id),
    }));
  }

  setLastReadDate = () => {
    const nowDate = new Date();
    const isoSting = nowDate.toISOString();

    this.lastReadDate = isoSting;
    this.updateUserInfo();
  };

  getStatusOptions = (columnId: string) => {
    const currentCol = this.columns.find(column => column.title === columnId);
    const settings = currentCol?.settingsStr && JSON.parse(currentCol?.settingsStr);
    const labels = settings.labels;

    const options = Object.keys(labels)
      .map(key => {
        return { id: key, title: labels[key], value: true };
      })
      .filter(item => item.title?.trim());

    return options;
  };

  getTagsOptions = () => {
    const tagsInFirstGroup = [] as number[];

    this.structure.tools.forEach(tool => {
      const tagsIds = tool.profession.map(tag => tag.id);
      tagsInFirstGroup.push(...tagsIds);
    });

    const uniqueItems = [...new Set(tagsInFirstGroup)];

    const options = uniqueItems.map(id => {
      return { id: String(id), title: this.tagsRecord[id].name, value: true };
    });

    return options;
  };

  getRelatedResources = (ids: number[]) => {
    return ids.map(id => {
      const currentTool = this.structure.tools.find(tool => String(id) === tool.id);

      return { id, name: currentTool?.title || 'N/A' };
    });
  };

  private handleError(error: any) {
    function is401Error(error: any) {
      return error?.response.status === 401;
    }
    // Interceptor will automatically log out
    if (is401Error(error)) return;

    runInAction(() => {
      this.error = 'Something went wrong';
    });
  }
}

export const appStore = new AppStore();
// this.settingsLabelIds = userInfo?.settingsIds || null;
