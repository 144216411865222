import { FC, useRef, useState } from 'react';
import { DialogContentContainer, Dialog, useClickOutside } from 'monday-ui-react-core';
import { CheckboxesList } from '..';
import { ReactComponent as ChevronTop } from '../../assets/filter-chevron-top.svg';
import cn from 'classnames';
import styles from './Filter.module.scss';

interface FilterProps {
  title: string;
  options: { title: string; id: string; value: boolean }[];
  onOptionChange(id: string, key: string, optionsValue?: boolean): void;
  valueKey: string;
}

export const Filter: FC<FilterProps> = ({ title, options, onOptionChange, valueKey }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const filterRef = useRef<HTMLDivElement | null>(null);

  function onOpenClick() {
    setIsModalOpen(true);
  }

  function onCloseClick() {
    //setTimeout is used to ensure it is called after onOpenClick function
    setTimeout(() => {
      setIsModalOpen(false);
    });
  }

  useClickOutside({
    ref: filterRef,
    callback: onCloseClick,
  });

  const isFilterActive = options.some(option => !option.value);

  return (
    <div className={styles.wrapper}>
      <Dialog
        content={() => (
          <DialogContentContainer ref={filterRef} className={styles.dialog}>
            <CheckboxesList
              onOptionChange={onOptionChange}
              options={options}
              withColumn
              valueKey={valueKey}
              generalOption={title}
            />
          </DialogContentContainer>
        )}
        open={isModalOpen}
        disable={!isModalOpen}
        position={Dialog.positions.BOTTOM_START}
        zIndex={10}
      >
        <div
          onClick={onOpenClick}
          className={cn(styles.filter, (isFilterActive || isModalOpen) && styles.active)}
        >
          <span className={styles.title}>{title}</span>
          <div className={styles.icon}>
            <ChevronTop />
          </div>
        </div>
      </Dialog>
    </div>
  );
};
