import { FC } from 'react';
import { ReactComponent as MondayLogo } from '../../assets/monday-logo.svg';
import { ReactComponent as NoAccessLogo } from '../../assets/no-access-logo.svg';
import { LoginButton } from '../LoginButton';
import styles from './PreScreen.module.scss';

export const PreScreen: FC<{ isLogin?: boolean }> = ({ isLogin }) => {
  return (
    <div className={styles.block}>
      <div>
        <MondayLogo />
      </div>
      <div className={styles.center}>
        <NoAccessLogo />
        {isLogin ? (
          <>
            <h2 className={styles.loginTitle}>Welcome to the designers' hub</h2>
            <span className={styles.loginText}>One place for all the resources you need</span>
            <LoginButton />
          </>
        ) : (
          <>
            <h2 className={styles.title}>Designers only.</h2>
            <span className={styles.text}>Sorry, this page is off-limit to non-designers.</span>
          </>
        )}
      </div>
    </div>
  );
};
