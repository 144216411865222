/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface NotificationsMutedProps extends React.SVGAttributes<SVGElement> {
size?: string | number;
}
const NotificationsMuted: React.FC<NotificationsMutedProps> = ({size, ...props}) => (
  <svg viewBox="0 0 21 21" fill="currentColor" width={ size || "21" } height={ size || "21" } {...props}>
    <path d="M10.4956 2.74902C10.9099 2.74902 11.2456 3.08481 11.2456 3.49902V4.30961C12.5502 4.45725 13.7741 5.04239 14.7116 5.97983C15.8046 7.07292 16.4187 8.55547 16.4187 10.1013C16.4187 12.4396 16.6683 13.7538 16.8915 14.457C17.0024 14.8065 17.1044 14.9975 17.1601 15.0859C17.1791 15.1159 17.1926 15.134 17.1994 15.1426C17.4135 15.3446 17.4909 15.6555 17.392 15.9367C17.2864 16.237 17.0028 16.4379 16.6845 16.4379H13.4485C13.4472 16.5028 13.4374 16.5686 13.4182 16.6338C13.2395 17.244 12.8681 17.7799 12.3596 18.1613C11.851 18.5428 11.2327 18.749 10.5971 18.749C9.96144 18.749 9.34307 18.5428 8.83451 18.1613C8.32597 17.7799 7.95466 17.244 7.77587 16.6338C7.75675 16.5686 7.74691 16.5028 7.74557 16.4379H7.58193H4.49561C4.21353 16.4379 3.9553 16.2796 3.82729 16.0283C3.7015 15.7813 3.72234 15.4855 3.88048 15.2588C3.88214 15.2562 3.88581 15.2504 3.89129 15.2411C3.90563 15.2169 3.93242 15.1692 3.968 15.0944C4.03913 14.9449 4.14571 14.687 4.25798 14.2934C4.4825 13.5064 4.72946 12.1782 4.76135 10.0954C4.76291 8.5517 5.37683 7.07153 6.46853 5.97983C7.36061 5.08776 8.51206 4.51471 9.74564 4.33411V3.49902C9.74564 3.08481 10.0814 2.74902 10.4956 2.74902ZM9.30265 16.4379C9.40258 16.6434 9.55025 16.8231 9.73451 16.9613C9.9836 17.1482 10.2862 17.249 10.5971 17.249C10.9079 17.249 11.2105 17.1482 11.4596 16.9613C11.6439 16.8231 11.7915 16.6434 11.8915 16.4379H9.30265ZM7.52919 7.04049C8.34098 6.2287 9.442 5.77264 10.59 5.77264C11.7381 5.77264 12.8391 6.2287 13.6509 7.04049C14.4627 7.85228 14.9187 8.9533 14.9187 10.1013C14.9187 12.5343 15.177 14.0134 15.4617 14.9107L15.4704 14.9379H7.58193H5.63057C5.65381 14.8641 5.67715 14.7865 5.70044 14.7049C5.96742 13.769 6.2281 12.3055 6.26126 10.1127L6.26135 10.1127V10.1013C6.26135 8.9533 6.71741 7.85228 7.52919 7.04049Z"
      fill="currentColor" fillRule="evenodd" clipRule="evenodd" />
    <path d="M18.0766 3.93152C18.1954 4.10247 18.2419 4.30784 18.2071 4.50842C18.1724 4.709 18.0588 4.89073 17.8882 5.01892L3.42542 17.6654C3.24026 17.7751 3.01783 17.818 2.80059 17.7859C2.58334 17.7538 2.38651 17.649 2.24767 17.4914C2.17869 17.4051 2.12884 17.307 2.10097 17.2028C2.0731 17.0987 2.06777 16.9905 2.08528 16.8844C2.10278 16.7783 2.14278 16.6765 2.20298 16.5847C2.26319 16.493 2.34241 16.4131 2.43611 16.3496L16.84 3.75754C17.0299 3.6317 17.2656 3.57946 17.4967 3.61197C17.7278 3.64449 17.9359 3.75917 18.0766 3.93152Z"
      fill="currentColor" fillRule="evenodd" clipRule="evenodd" />
  </svg>
);
NotificationsMuted.displayName = 'NotificationsMuted';
export default NotificationsMuted;
/* tslint:enable */
/* eslint-enable */
