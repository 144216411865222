import { observer } from 'mobx-react-lite';
import { Plugins, Tools, Updates } from '..';
import { appStore } from '../../stores';
import styles from './Main.module.scss';

export const Main = observer(() => {
  const { plugins, updates } = appStore.structure;
  return (
    <div className={styles.main}>
      <Tools />
      {!!updates.length && <Updates />}
      {!!plugins.length && <Plugins />}
    </div>
  );
});
