/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface MinimizeProps extends React.SVGAttributes<SVGElement> {
size?: string | number;
}
const Minimize: React.FC<MinimizeProps> = ({size, ...props}) => (
  <svg viewBox="0 0 20 20" fill="currentColor" width={ size || "20" } height={ size || "20" } {...props}>
    <path d="M3.19197 16.2917C2.92331 16.5815 2.93863 17.0359 3.2262 17.3066 3.51377 17.5774 3.96468 17.5619 4.23334 17.2721L8.02995 13.1769 8.02995 15.4257C8.02995 15.8223 8.34898 16.1438 8.74251 16.1438 9.13605 16.1438 9.45508 15.8223 9.45508 15.4257L9.45508 11.3565C9.45508 11.2731 9.44096 11.193 9.41501 11.1185 9.37819 11.0125 9.31606 10.9137 9.22896 10.8317 9.08765 10.6986 8.9069 10.6347 8.7277 10.6385L4.97012 10.6385C4.57658 10.6385 4.25756 10.96 4.25756 11.3565 4.25756 11.7531 4.57658 12.0746 4.97012 12.0746L7.1015 12.0746 3.19197 16.2917zM15.0302 9.36157C15.4238 9.36157 15.7428 9.04007 15.7428 8.64349 15.7428 8.2469 15.4238 7.9254 15.0302 7.9254L12.8984 7.9254 16.8081 3.70817C17.0768 3.41838 17.0615 2.96398 16.7739 2.69324 16.4863 2.42249 16.0354 2.43794 15.7668 2.72773L11.9704 6.82271 11.9704 4.57434C11.9704 4.17775 11.6514 3.85625 11.2579 3.85625 10.8643 3.85625 10.5453 4.17775 10.5453 4.57434L10.5453 8.62321C10.5398 8.82187 10.6157 9.02186 10.7711 9.1682 10.9084 9.29744 11.0829 9.36147 11.257 9.36157L15.0302 9.36157z"
      fill="currentColor" />
  </svg>
);
Minimize.displayName = 'Minimize';
export default Minimize;
/* tslint:enable */
/* eslint-enable */
