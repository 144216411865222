/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface RotateProps extends React.SVGAttributes<SVGElement> {
size?: string | number;
}
const Rotate: React.FC<RotateProps> = ({size, ...props}) => (
  <svg viewBox="0 0 20 20" fill="currentColor" width={ size || "20" } height={ size || "20" } {...props}>
    <path d="M15.351 6.91473C13.7396 4.18522 10.3205 2.99372 7.3132 4.25094C4.02713 5.62468 2.47712 9.40179 3.85098 12.6873C5.22485 15.9729 9.00248 17.5228 12.2886 16.1491C14.2318 15.3367 15.5682 13.6847 16.0519 11.7875C16.1542 11.3861 16.5625 11.1437 16.9639 11.246C17.3653 11.3484 17.6077 11.7567 17.5054 12.1581C16.9103 14.492 15.2633 16.5313 12.8672 17.533C8.81688 19.2262 4.16059 17.3159 2.46709 13.266C0.773577 9.216 2.68431 4.56025 6.73464 2.86701C10.2115 1.41353 14.1348 2.6154 16.2473 5.54663L16.25 4.45764C16.251 4.04343 16.5876 3.70847 17.0018 3.70948C17.4161 3.7105 17.751 4.04711 17.75 4.46132L17.7421 7.6706C17.7411 8.08482 17.4045 8.41978 16.9903 8.41876L13.7806 8.41088C13.3664 8.40987 13.0314 8.07326 13.0324 7.65905C13.0335 7.24483 13.3701 6.90987 13.7843 6.91089L15.351 6.91473Z"
      fill="currentColor" fillRule="evenodd" clipRule="evenodd" />
  </svg>
);
Rotate.displayName = 'Rotate';
export default Rotate;
/* tslint:enable */
/* eslint-enable */
