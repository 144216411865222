import { useState } from 'react';
import { VISIBILITY_ITEMS_STEP, INITIAL_VISIBILITY_ITEMS_COUNT } from '../constants';

export const useShowMore = (itemsLength: number) => {
  const [visibleItems, setVisibleItems] = useState(INITIAL_VISIBILITY_ITEMS_COUNT);

  const handleShowMore = () => {
    setVisibleItems(prevVisible => Math.min(prevVisible + VISIBILITY_ITEMS_STEP, itemsLength));
  };

  return { visibleItems, handleShowMore };
};
