import { FC, useEffect } from 'react';
import { useShowMore } from '../../hooks';
import cn from 'classnames';
import { Heading } from 'monday-ui-react-core';
import { Navigation, Label, ShowMore } from '..';
import { ReactComponent as FavoriteIcon } from '../../assets/favorite-icon.svg';
import { ReactComponent as ExternalLink } from '../../assets/external-link-icon.svg';
import { Tool } from '../../types';
import { appStore } from '../../stores';
import styles from './GalleryView.module.scss';
import sharedStyles from '../../shared.module.scss';

export const GalleryView: FC<{ items: Tool[] }> = ({ items }) => {
  const itemsCount = items.length;
  const { visibleItems, handleShowMore } = useShowMore(itemsCount);
  const { updateFavorites, setSelectedItemId } = appStore;

  function openPopup(id: string | null) {
    setSelectedItemId(id);
  }

  // Preload images
  useEffect(() => {
    items.forEach(item => {
      const img = new Image();
      img.src = item.imgSrc;

      const smallImg = new Image();
      smallImg.src = item.smallImgSrc;
    });
  }, [items]);

  return (
    <div className={styles.list}>
      {items.slice(0, visibleItems).map(item => (
        <div key={item.id} className={styles.item}>
          <img src={item.imgSrc} className={styles.image} onClick={() => openPopup(item.id)} />

          <div className={styles.content}>
            <div className={styles.inlineWrapper}>
              <div className={styles.labels}>
                <Label title={item.resourcesType.text} kind="line" />
                {item.isNew && <Label primary title="New" />}
              </div>
              <div className={styles.icons}>
                <div
                  className={cn(
                    sharedStyles.icon,
                    sharedStyles.favIcon,
                    item.isFavorite && sharedStyles.iconActive
                  )}
                  onClick={() => updateFavorites(item.id)}
                >
                  <FavoriteIcon />
                </div>
                <a href={item.linkUrl} className={sharedStyles.icon} target="_blank">
                  <ExternalLink />
                </a>
              </div>
            </div>
            <span className={styles.action} onClick={() => openPopup(item.id)}>
              <Heading
                type={Heading.types.h4}
                className={styles.title}
                value={item.title}
                ellipsis
                ellipsisMaxLines={2}
              />

              <Heading
                type={Heading.types.h6}
                className={styles.description}
                value={item.description}
                ellipsis
                ellipsisMaxLines={3}
              />
            </span>
            <span className={styles.link}>
              <Navigation onClick={() => openPopup(item.id)} text="Learn more" />
            </span>
          </div>
          <div className={styles.date}>
            {item.eventTitle} {item.date}
          </div>
        </div>
      ))}

      {visibleItems < itemsCount && (
        <ShowMore onClick={handleShowMore} marginTop={80} align="center" />
      )}
    </div>
  );
};
