import { FC } from 'react';
import { ReactComponent as LinkChevron } from '../../assets/link-chevron-icon.svg';
import styles from './Navigation.module.scss';

type NavigationProps = {
  text: string;
  isLink?: boolean;
  onClick?: () => void;
  href?: string;
};

export const Navigation: FC<NavigationProps> = ({ text, href, onClick, isLink = false }) => {
  return (
    <>
      {isLink ? (
        <a className={styles.link} target="_blank" href={href}>
          {text}
          <LinkChevron />
        </a>
      ) : (
        <div className={styles.link} onClick={onClick}>
          {text}
          <LinkChevron />
        </div>
      )}
    </>
  );
};
