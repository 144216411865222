import { Tool, Option, NotificationData } from '../types';

export function getFilteredTools(tools: Tool[], filters: ToolsFilters) {
  const { searchValue, platforms, professions, resourceType } = filters;

  const filteredByValueTools = filterBySearchValue(tools, searchValue);
  const filteredByPlatformsTools = filterByPlatform(filteredByValueTools, platforms);
  const filteredByProfessionsTools = filterByProfessions(filteredByPlatformsTools, professions);
  const filteredByResourceType = filterResourceType(filteredByProfessionsTools, resourceType);

  return filteredByResourceType;
}

function filterBySearchValue(tools: Tool[], searchValue: string) {
  if (!searchValue) return tools;

  const filteredByValueUpdates = tools.filter(tool => {
    const isMet =
      isTextIncluded(tool.title, searchValue) || isTextIncluded(tool.description, searchValue);

    return isMet;
  });

  return filteredByValueUpdates;
}

export function filterByPlatform(tools: Tool[], platforms: Option[]) {
  const isNeedToFilter = shouldFilterItems(platforms);

  if (!isNeedToFilter) return tools;

  const selectedLabelIds = platforms.filter(platform => platform.value).map(option => option.id);

  const filteredTools = tools.filter(tool => {
    return selectedLabelIds.includes(String(tool.platform.id));
  });

  return filteredTools;
}

function filterByProfessions(tools: Tool[], professions: Option[]) {
  const isNeedToFilter = shouldFilterItems(professions);

  if (!isNeedToFilter) return tools;

  const selectedLabelIds = professions.filter(platform => platform.value).map(option => option.id);

  const filteredTools = tools.filter(tool => {
    return tool.profession.some(prof => selectedLabelIds.includes(String(prof.id)));
  });

  return filteredTools;
}

function filterResourceType(tools: Tool[], resourcesType: Option[]) {
  const isNeedToFilter = shouldFilterItems(resourcesType);

  if (!isNeedToFilter) return tools;

  const selectedLabelIds = resourcesType
    .filter(resource => resource.value)
    .map(option => option.id);

  const filteredTools = tools.filter(tool => {
    return selectedLabelIds.includes(String(tool.resourcesType.id));
  });

  return filteredTools;
}

export function notificationFilter(notificationList: NotificationData[], filterOptions: Option[]) {
  const isNeedToFilter = shouldFilterItems(filterOptions);

  if (!isNeedToFilter) return notificationList;

  const selectedLabelIds = filterOptions.filter(option => option.value).map(option => option.id);

  const filteredNotifications = notificationList.filter(notification => {
    return notification.labels.some(label => selectedLabelIds.includes(String(label.id)));
  });

  return filteredNotifications;
}

export function isFilterApplied(selectedFilters: ToolsFilters) {
  const { searchValue, platforms, professions, resourceType } = selectedFilters;
  const isSearchValue = !!searchValue;
  const isAppliedPlatform = platforms.some(option => !option.value);
  const isAppliedProfession = professions.some(option => !option.value);
  const isAppliedResourceType = resourceType.some(option => !option.value);

  return isSearchValue || isAppliedPlatform || isAppliedProfession || isAppliedResourceType;
}

function shouldFilterItems(options: Option[]) {
  return options.some(option => !option.value);
}

function isTextIncluded(text: string, searchValue: string): boolean {
  return text.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase());
}

interface ToolsFilters {
  platforms: Option[];
  professions: Option[];
  resourceType: Option[];
  searchValue: string;
}
