/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface WorkflowProps extends React.SVGAttributes<SVGElement> {
size?: string | number;
}
const Workflow: React.FC<WorkflowProps> = ({size, ...props}) => (
  <svg viewBox="0 0 20 20" fill="currentColor" width={ size || "20" } height={ size || "20" } {...props}>
    <path d="M2.88125 13.6703V16.2203H6.49375V13.6703H2.88125zM2.03125 12.2891C1.73785 12.2891 1.5 12.5269 1.5 12.8203V17.0703C1.5 17.3637 1.73785 17.6016 2.03125 17.6016H7.34375C7.63715 17.6016 7.875 17.3637 7.875 17.0703V12.8203C7.875 12.5269 7.63715 12.2891 7.34375 12.2891H2.03125zM10.0588 4.35284L7.62811 6.78356 10.0588 9.21429 12.4896 6.78356 10.0588 4.35284zM10.4345 2.7751C10.227 2.56764 9.89065 2.56764 9.68319 2.7751L6.05038 6.40791C5.84291 6.61538 5.84291 6.95175 6.05038 7.15921L9.68319 10.792C9.89065 10.9994 10.227 10.9994 10.4345 10.792L14.0673 7.15921C14.2748 6.95175 14.2748 6.61538 14.0673 6.40791L10.4345 2.7751z"
      fill="currentColor" fillRule="evenodd" clipRule="evenodd" />
    <path d="M6.97168 6.97582C6.97168 7.35724 6.66248 7.66645 6.28105 7.66645H5.37793V12.8195C5.37793 13.201 5.06873 13.5102 4.6873 13.5102 4.30588 13.5102 3.99668 13.201 3.99668 12.8195V7.50707C3.99668 6.83225 4.54373 6.2852 5.21855 6.2852H6.28105C6.66248 6.2852 6.97168 6.5944 6.97168 6.97582zM17.1188 13.6703V16.2203H13.5063V13.6703H17.1188zM17.9688 12.2891C18.2622 12.2891 18.5 12.5269 18.5 12.8203V17.0703C18.5 17.3637 18.2622 17.6016 17.9688 17.6016H12.6562C12.3628 17.6016 12.125 17.3637 12.125 17.0703V12.8203C12.125 12.5269 12.3628 12.2891 12.6562 12.2891H17.9688z"
      fill="currentColor" fillRule="evenodd" clipRule="evenodd" />
    <path d="M13.0283 6.97582C13.0283 7.35724 13.3375 7.66645 13.7189 7.66645H14.6221V12.8195C14.6221 13.201 14.9313 13.5102 15.3127 13.5102C15.6941 13.5102 16.0033 13.201 16.0033 12.8195V7.50707C16.0033 6.83225 15.4563 6.2852 14.7814 6.2852H13.7189C13.3375 6.2852 13.0283 6.5944 13.0283 6.97582Z"
      fill="currentColor" fillRule="evenodd" clipRule="evenodd" />
  </svg>
);
Workflow.displayName = 'Workflow';
export default Workflow;
/* tslint:enable */
/* eslint-enable */
