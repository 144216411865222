/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface LaunchProps extends React.SVGAttributes<SVGElement> {
size?: string | number;
}
const Launch: React.FC<LaunchProps> = ({size, ...props}) => (
  <svg viewBox="0 0 20 20" fill="currentColor" width={ size || "20" } height={ size || "20" } {...props}>
    <g clipPath="url(#clip0_26224_38435)">
      <path d="M14.2128 2.04144C15.3307 5.62227 14.2896 9.53829 11.5184 12.0918L11.3107 12.2828C11.0451 12.5271 10.9911 12.926 11.1823 13.2321C12.2391 14.9246 11.6797 17.1957 9.89746 18.1852C9.89751 18.1852 9.8974 18.1853 9.89746 18.1852L9.74441 18.2702L8.95049 15.3063C8.89902 15.1142 8.77331 14.9503 8.60103 14.8509L5.92541 13.3061C5.75313 13.2066 5.54839 13.1797 5.35624 13.2312L2.39253 14.0256L2.38955 13.8507C2.38955 13.8508 2.38955 13.8507 2.38955 13.8507C2.35542 11.8125 4.04251 10.1923 6.03672 10.2613C6.39728 10.2738 6.71563 10.0278 6.7945 9.67571L6.85633 9.39972C7.68196 5.72334 10.5528 2.86378 14.2128 2.04144ZM15.5436 1.28623C15.352 0.729332 14.7795 0.398795 14.2015 0.511372C9.9244 1.34396 6.52529 4.57222 5.46236 8.77941C2.90132 9.01016 0.844582 11.1859 0.889765 13.8759L0.899717 14.46C0.9135 15.2278 1.64428 15.7783 2.3853 15.5804L5.44447 14.7605L7.58196 15.9946L8.40126 19.0531C8.60026 19.7958 9.44311 20.152 10.114 19.7805L10.6252 19.4969C12.9774 18.191 13.8334 15.3219 12.7528 12.9886C15.8649 9.96423 16.961 5.4065 15.5436 1.28623ZM11.6828 6.42318C11.4229 6.27315 11.092 6.36177 10.9419 6.62171C10.7917 6.88177 10.8804 7.21248 11.1404 7.36263C11.4005 7.51277 11.7312 7.42416 11.8813 7.1641C12.0313 6.90436 11.9425 6.57314 11.6828 6.42318ZM9.64285 5.87171C10.2073 4.89409 11.4557 4.56004 12.4328 5.12414C13.41 5.68832 13.7445 6.93692 13.1804 7.9141C12.616 8.8916 11.3679 9.22603 10.3904 8.66167C9.41291 8.09731 9.07849 6.84921 9.64285 5.87171Z"
        fill="currentColor" fillRule="evenodd" clipRule="evenodd" />
    </g>
    <defs>
      <clipPath id="clip0_26224_38435">
        <path fill="#fff" d="M0 0H20V20H0z" />
      </clipPath>
    </defs>
  </svg>
);
Launch.displayName = 'Launch';
export default Launch;
/* tslint:enable */
/* eslint-enable */
