import { FC, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { CheckboxesList, NotificationsList } from '../../ui-kit';
import { appStore } from '../../stores';
import { GENERAL_OPTION_ID } from '../../constants';
import { notificationFilter } from '../../services';
import { ReactComponent as SettingsIcon } from '../../assets/settings.icon.svg';

import sharedStyles from '../../shared.module.scss';
import styles from './Notifications.module.scss';

export const Notifications: FC = () => {
  const { notificationsSettingsOptions, notificationList, setLastReadDate, setSettingsLabelIds } =
    appStore;

  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [filterOption, setFilterOption] = useState(notificationsSettingsOptions);

  const filteredNotifications = useMemo(() => {
    return notificationFilter(notificationList, filterOption);
  }, [filterOption, notificationList]);

  function onOptionChange(id: string, _: string, optionsValue: boolean) {
    setFilterOption(prev => {
      const updatedOptions =
        id === GENERAL_OPTION_ID
          ? prev.map(option => {
              return { ...option, value: optionsValue };
            })
          : prev.map(option => {
              return { ...option, value: option.id === id ? !option.value : option.value };
            });
      setSettingsLabelIds(updatedOptions.filter(option => option.value).map(option => option.id));

      return updatedOptions;
    });
  }

  useEffect(() => {
    setLastReadDate();
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h4 className={styles.title}>Notifications</h4>
        <div
          onClick={() => setIsSettingsOpen(!isSettingsOpen)}
          className={cn(sharedStyles.icon, isSettingsOpen && styles.open)}
        >
          <SettingsIcon />
        </div>
      </div>

      <div className={styles.content}>
        {isSettingsOpen ? (
          <div className={styles.settings}>
            <span className={styles.name}>Settings</span>
            <span className={styles.desc}>
              Manage your notifications to stay updated on <br /> the areas that are most important
              to you.
            </span>
            <CheckboxesList
              onOptionChange={onOptionChange}
              options={filterOption}
              generalOption="All professions"
            />
          </div>
        ) : (
          <NotificationsList items={filteredNotifications} />
        )}
      </div>
    </div>
  );
};
