import { DateTime, Duration } from 'luxon';
import { MAX_DURATION_DAYS } from '../constants';

export function isDateWithinMaxDuration(dateString: string) {
  const startDate = DateTime.fromISO(dateString);
  const today = DateTime.now();
  const differenceInDays = today.diff(startDate, 'days').days;
  return differenceInDays < MAX_DURATION_DAYS;
}

export function getFormattedDate({
  date,
  withPrefix = false,
}: {
  date: string;
  withPrefix?: boolean;
}): string {
  const createdDate = DateTime.fromISO(date);
  const nowDate = DateTime.now();
  const intervalInMs = nowDate.toMillis() - createdDate.toMillis();
  const isSameYear = createdDate.year === nowDate.year;

  const { years, days, hours, minutes } = Duration.fromMillis(intervalInMs)
    .shiftTo('days', 'minutes', 'hours', 'years', 'seconds')
    .toObject();

  if (years || (typeof days === 'number' && days > 28)) {
    const formattedDate = DateTime.fromISO(date).toLocaleString(
      { day: 'numeric', month: 'short', year: isSameYear ? undefined : 'numeric' },
      { locale: 'en-GB' }
    );

    return withPrefix ? `on ${formattedDate}` : formattedDate;
  }

  let formattedString = '';

  if (minutes) {
    formattedString = `${minutes}m`;
  }

  if (hours) {
    formattedString = `${hours}h`;
  }

  if (days) {
    formattedString = `${days}d`;
  }

  if (!formattedString) return 'just now';

  return withPrefix ? `${formattedString} ago` : formattedString;
}
