import { Tool } from '../types';
import _ from 'lodash';

/**
 * Tools should be shuffled only once
 * Because the app does not re-load data from monday.com board
 * we can use the variable to store shuffled ids
 */

let shuffledIds: string[] | null = null; // shuffled tools ids

export function getShuffledItems(tools: Tool[]) {
  if (!_.isNull(shuffledIds)) {
    return tools.sort((a, b) => shuffledIds!.indexOf(a.id) - shuffledIds!.indexOf(b.id));
  }

  const shuffledTools = _.shuffle(tools);
  const sortedArray = [...shuffledTools].sort((a, b) => orderFor(a) - orderFor(b));
  shuffledIds = sortedArray.map(t => t.id);
  return sortedArray;
}

function orderFor(tool: Tool) {
  switch (tool.eventTitle) {
    case 'Added':
      return 0;
    case 'Updated':
      return 1;
    case 'Reviewed':
      return 2;
    default:
      return 3;
  }
}
