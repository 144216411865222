/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface ImageProps extends React.SVGAttributes<SVGElement> {
size?: string | number;
}
const Image: React.FC<ImageProps> = ({size, ...props}) => (
  <svg viewBox="0 0 20 20" fill="currentColor" width={ size || "20" } height={ size || "20" } {...props}>
    <path d="M1.82141 4.56839C1.82141 3.04961 3.05263 1.81839 4.57141 1.81839H15.4286C16.9473 1.81839 18.1786 3.04961 18.1786 4.56839V15.4255C18.1786 16.9443 16.9473 18.1755 15.4286 18.1755H4.57141C3.05263 18.1755 1.82141 16.9443 1.82141 15.4255V4.56839ZM4.57141 3.31839C3.88105 3.31839 3.32141 3.87803 3.32141 4.56839V15.4255C3.32141 16.1159 3.88105 16.6755 4.57141 16.6755H15.4286C16.1189 16.6755 16.6786 16.1159 16.6786 15.4255V4.56839C16.6786 3.87803 16.1189 3.31839 15.4286 3.31839H4.57141ZM6.28295 6.3898L6.28502 6.3898H6.28571C6.5604 6.3898 6.82384 6.49892 7.01808 6.69315C7.21231 6.88739 7.32143 7.15083 7.32143 7.42552C7.32143 7.63025 7.26075 7.83038 7.14707 8.00064C7.03338 8.17091 6.87179 8.30366 6.6827 8.38213C6.49361 8.4606 6.2855 8.48127 6.08467 8.44153C5.88384 8.40179 5.69929 8.30342 5.55433 8.15885C5.40937 8.01428 5.31051 7.82999 5.27024 7.62926C5.22996 7.42854 5.25008 7.22038 5.32805 7.03108C5.40602 6.84178 5.53834 6.67983 5.7083 6.56569C5.87825 6.45156 6.07822 6.39035 6.28295 6.3898ZM11.7308 8.54731C11.5156 8.54558 11.3032 8.59644 11.1122 8.69549C10.9218 8.79421 10.7583 8.93781 10.6359 9.11387L9.57506 10.6293L9.25659 10.3747C9.11353 10.2605 8.94829 10.1772 8.77136 10.1302C8.59443 10.0832 8.40966 10.0734 8.22876 10.1016C8.04787 10.1298 7.8748 10.1952 7.72053 10.2938C7.56634 10.3923 7.43413 10.522 7.33259 10.6742L5.39295 13.5806C5.23937 13.8107 5.22496 14.1067 5.35544 14.3507C5.48593 14.5946 5.74012 14.747 6.01679 14.747H15.1429C15.4191 14.747 15.673 14.5951 15.8037 14.3517C15.9344 14.1082 15.9206 13.8127 15.7679 13.5825L12.8194 9.13598L12.8175 9.13325C12.6978 8.95444 12.5363 8.80758 12.3468 8.70546C12.1574 8.60334 11.9459 8.54905 11.7308 8.54731ZM13.7456 13.247L11.7161 10.1864L10.3455 12.1444C10.2267 12.3141 10.0429 12.4272 9.83782 12.4567C9.63272 12.4861 9.42456 12.4295 9.26272 12.3001L8.47253 11.6683L7.41899 13.247H13.7456Z"
      fill="currentColor" fillRule="evenodd" clipRule="evenodd" />
  </svg>
);
Image.displayName = 'Image';
export default Image;
/* tslint:enable */
/* eslint-enable */
