import { Navigation } from '../../ui-kit';
import { ReactComponent as FlowingWave } from '../../assets/shapes/updates-flowing-wave.svg';
import { ReactComponent as VerticalBar } from '../../assets/shapes/updates-vertical-bar.svg';
import { ReactComponent as CurvedPath } from '../../assets/shapes/updates-curved-path.svg';
import { ReactComponent as CurvedLine } from '../../assets/shapes/update-curved-line.svg';
import { ReactComponent as DottedLine } from '../../assets/shapes/updates-dotted-line.svg';
import { appStore } from '../../stores';
import styles from './Updates.module.scss';

export const Updates = () => {
  const { updates } = appStore.structure;

  const lastThreeUpdates = updates.slice(-3).reverse();

  return (
    <div className={styles.wrapper}>
      <div className={styles.shape1}>
        <FlowingWave />
      </div>
      <div className={styles.shapesWrapper}>
        <div className={styles.updates}>
          <h2 className={styles.title}>Last design talk updates</h2>
          <div className={styles.list}>
            <div className={styles.shape4}>
              <CurvedLine />
            </div>
            {lastThreeUpdates.map(item => {
              return (
                <a href={item.linkUrl} target="_blank" className={styles.item} key={item.id}>
                  <span className={styles.date}>{item.title}</span>
                  <Navigation text="Open" />
                </a>
              );
            })}
            <div className={styles.shape3}>
              <CurvedPath />
            </div>
          </div>
        </div>
        <div className={styles.shape5}>
          <DottedLine />
        </div>
        <div className={styles.shape2}>
          <VerticalBar />
        </div>
      </div>
    </div>
  );
};
