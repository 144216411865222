/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface BoldProps extends React.SVGAttributes<SVGElement> {
size?: string | number;
}
const Bold: React.FC<BoldProps> = ({size, ...props}) => (
  <svg viewBox="0 0 20 20" fill="currentColor" width={ size || "20" } height={ size || "20" } {...props}>
    <g fill="currentColor" clipPath="url(#clip0)" fillRule="evenodd" clipRule="evenodd">
      <path d="M6.84395 2.7332H11.3773C12.4912 2.7332 13.5595 3.17571 14.3472 3.96339C15.1349 4.75107 15.5774 5.81939 15.5774 6.93333C15.5774 8.04727 15.1349 9.11559 14.3472 9.90327C13.5595 10.6909 12.4912 11.1335 11.3773 11.1335H5.77728C5.07765 11.1335 4.51048 10.5663 4.51048 9.86666V5.06666C4.51048 4.44779 4.75633 3.85426 5.19394 3.41666C5.63155 2.97905 6.22507 2.7332 6.84395 2.7332ZM7.04407 5.26679H11.3773C11.8193 5.26679 12.2432 5.44237 12.5557 5.75491C12.8682 6.06745 13.0438 6.49134 13.0438 6.93333C13.0438 7.37532 12.8682 7.79921 12.5557 8.11175C12.2432 8.42429 11.8193 8.59987 11.3773 8.59987H7.04407V5.26679Z"
      />
      <path d="M4.51048 9.86666C4.51048 9.16703 5.07765 8.59987 5.77728 8.59987H11.3239C12.5793 8.59987 13.7833 9.09857 14.671 9.98627C15.5587 10.874 16.0574 12.0779 16.0574 13.3333C16.0574 14.5877 15.5595 15.7908 14.6731 16.6783C13.7866 17.5659 12.5842 18.0653 11.3298 18.0668L6.84821 18.0668C6.22934 18.0668 5.63581 17.821 5.1982 17.3833C4.7606 16.9457 4.51475 16.3522 4.51475 15.7333V9.97139C4.51192 9.93685 4.51048 9.90193 4.51048 9.86666ZM7.04834 11.1335V15.5332H11.3273C11.9101 15.5323 12.4686 15.3002 12.8805 14.8879C13.2924 14.4754 13.5238 13.9163 13.5238 13.3333C13.5238 12.7499 13.292 12.1903 12.8795 11.7778C12.4669 11.3652 11.9074 11.1335 11.3239 11.1335H7.04834Z"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill="#fff" transform="translate(2 2)" d="M0 0H16V16H0z" />
      </clipPath>
    </defs>
  </svg>
);
Bold.displayName = 'Bold';
export default Bold;
/* tslint:enable */
/* eslint-enable */
