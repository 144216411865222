/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface PinProps extends React.SVGAttributes<SVGElement> {
size?: string | number;
}
const Pin: React.FC<PinProps> = ({size, ...props}) => (
  <svg viewBox="0 0 20 20" fill="currentColor" width={ size || "20" } height={ size || "20" } {...props}>
    <path d="M13.6948 2.36147C13.4306 2.26655 13.1486 2.23159 12.8692 2.25913C12.5898 2.28667 12.32 2.37602 12.0794 2.52071C11.8388 2.66539 11.6334 2.86179 11.4781 3.09566L11.4766 3.09792L9.17312 6.59363L4.33249 7.71144C4.08119 7.76694 3.84939 7.88899 3.66138 8.06484C3.47188 8.2421 3.33395 8.4674 3.26228 8.71679C3.1906 8.96618 3.18786 9.23033 3.25433 9.48116C3.32032 9.73017 3.45209 9.95681 3.63577 10.1373L6.61909 13.1211L2.46967 17.2706C2.17678 17.5634 2.17678 18.0383 2.46967 18.3312C2.76256 18.6241 3.23744 18.6241 3.53033 18.3312L7.67966 14.1819L10.665 17.1677L10.6654 17.1681C10.8474 17.3499 11.0747 17.4796 11.3238 17.5439C11.5728 17.6082 11.8346 17.6046 12.0818 17.5336C12.329 17.4627 12.5527 17.3268 12.7297 17.1402C12.9067 16.9536 13.0306 16.7231 13.0885 16.4724L13.0885 16.4723L14.2044 11.6351L17.7285 9.39994L17.729 9.39963C17.9683 9.24758 18.1704 9.04371 18.3204 8.80309C18.4704 8.56246 18.5643 8.29122 18.5954 8.00939C18.6265 7.72757 18.5938 7.44237 18.4999 7.17485C18.406 6.90734 18.2532 6.66434 18.0527 6.46381L18.0526 6.46369L14.3968 2.80795L14.3959 2.807C14.1981 2.60841 13.9586 2.45624 13.6948 2.36147ZM8.22793 12.6087L11.6507 16.032L12.812 10.998C12.8564 10.8058 12.9746 10.6389 13.1411 10.5332L16.9246 8.13355L16.9248 8.13342C16.9744 8.10188 17.0163 8.05962 17.0474 8.00974C17.0785 7.9598 17.098 7.9035 17.1045 7.84501C17.1109 7.78652 17.1041 7.72733 17.0846 7.67181C17.0651 7.61628 17.0334 7.56585 16.9918 7.52423L13.3348 3.86721L13.3334 3.8658C13.2923 3.82449 13.2425 3.79284 13.1877 3.77314C13.1328 3.75343 13.0743 3.74618 13.0163 3.7519C12.9583 3.75761 12.9023 3.77616 12.8524 3.80618C12.8028 3.83604 12.7603 3.8765 12.7282 3.92466L12.7276 3.92552L10.2605 7.66954C10.1545 7.8304 9.99071 7.9443 9.80301 7.98764L4.7697 9.14995L8.19223 12.573C8.19834 12.5788 8.20437 12.5846 8.21033 12.5906C8.21632 12.5965 8.22219 12.6026 8.22793 12.6087Z"
      fill="currentColor" fillRule="evenodd" clipRule="evenodd" />
  </svg>
);
Pin.displayName = 'Pin';
export default Pin;
/* tslint:enable */
/* eslint-enable */
