import { Item, Structure, Tag, User, ItemHistory } from '../types';
import { GROUP_NAMES, INITIAL_STRUCTURE_STATE } from '../constants';
import { getUpdatesInfo, getPluginsInfo, getToolsInfo } from './columnParser';

export function getStructure({
  items,
  users,
  assetsRecord,
  tagsRecord,
  favoritesIds,
  itemsHistory,
  isDraftMode,
}: {
  items: Item[];
  users: User[];
  assetsRecord: Record<string, string>;
  tagsRecord: Record<string, Tag>;
  favoritesIds: string[];
  itemsHistory: Record<string, ItemHistory[]>;
  isDraftMode: boolean;
}) {
  const initState = JSON.parse(JSON.stringify(INITIAL_STRUCTURE_STATE));
  const structure = items.reduce((acc, item) => {
    const groupName = item.group.title;
    let result;

    if (GROUP_NAMES.TOOLS.includes(groupName)) {
      result = getToolsInfo({
        item,
        users,
        assetsRecord,
        favoritesIds,
        tagsRecord,
        itemsHistory,
        isDraftMode,
      });
      if (!result) return acc;

      acc.tools = [...acc.tools, result];
      return acc;
    }

    if (groupName === GROUP_NAMES.UPDATES) {
      result = getUpdatesInfo({ item, assetsRecord, tagsRecord, isDraftMode });
      if (!result) return acc;

      acc.updates = [...acc.updates, result];
      return acc;
    }

    if (groupName === GROUP_NAMES.PLUGINS) {
      result = getPluginsInfo({ item, users, isDraftMode });
      if (!result) return acc;

      acc.plugins = [...acc.plugins, result];
      return acc;
    }

    acc.unsupportedGroup = [...acc.unsupportedGroup, item];
    return acc;
  }, initState as Structure);

  return structure;
}
