export const VISIBILITY_ITEMS_STEP = 6;
export const INITIAL_VISIBILITY_ITEMS_COUNT = 6;
export const GENERAL_OPTION_ID = 'general';
export const MILLISECONDS_PER_DAY = 1000 * 3600 * 24;
export const MAX_DURATION_DAYS = 14;
export const UPDATE_NOTIFICATION_TITLE = 'New design talk presentation';

export const GROUP_NAMES = {
  TOOLS: ['Brand', 'Product', 'Motion & video', 'Global', 'Marketing'],
  UPDATES: 'Last design talk updates',
  PLUGINS: 'Recommended plugins',
};

export const COLUMN_NAMES = {
  STATUS_FIRST_GROUP: 'Status for 1st group',
  STATUS_SECOND_GROUP: 'Status for 2nd and 3rd groups',
  STATUS_THIRD_GROUP: 'Status for 2nd and 3rd groups',
  LINK: 'Link',
  RESOURCE_TYPE: 'Resource type',
  PROFESSION: 'Profession',
  PLATFORM: 'Platform',
  DESCRIPTION: 'Description',
  MORE_INFO: 'More Info',
  IMAGE: 'Image',
  SMALL_IMAGE: 'Small image',
  OWNER: 'Owner',
  RELATED_RESOURCES: 'Related resources',
};

export const LABEL_NAMES = {
  UPDATED: 'Updated',
  PUBLISHED: 'Published',
  REVIEWED: 'Reviewed',
  ACTIVE: 'Active',
};

export const HISTORY_LABELS = [LABEL_NAMES.PUBLISHED, LABEL_NAMES.REVIEWED, LABEL_NAMES.UPDATED];

export const INITIAL_STRUCTURE_STATE = {
  updates: [],
  unsupportedGroup: [],
  tools: [],
  plugins: [],
};

export const SUPPORTED_DOMAINS = ['monday.com', 'leanylabs.com'];
