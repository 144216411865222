import { ColumnValue, ItemHistory, Item, Tag, User, EventType, Tool } from '../types';
import { COLUMN_NAMES, LABEL_NAMES } from '../constants';
import { getFormattedDate, isDateWithinMaxDuration } from '../utils';

export function getToolsInfo({
  item,
  users,
  assetsRecord,
  favoritesIds,
  tagsRecord,
  itemsHistory,
  isDraftMode,
}: {
  item: Item;
  users: User[];
  assetsRecord: Record<string, string>;
  favoritesIds: string[];
  tagsRecord: Record<string, Tag>;
  itemsHistory: Record<string, ItemHistory[]>;
  isDraftMode: boolean;
}) {
  const { columnValues, id, name } = item;
  const columnValueRecord = getColumnValueRecord(columnValues);

  const linkCol = columnValueRecord[COLUMN_NAMES.LINK];
  const statusCol = columnValueRecord[COLUMN_NAMES.STATUS_FIRST_GROUP];
  const smallImgCol = columnValueRecord[COLUMN_NAMES.SMALL_IMAGE];
  const imgCol = columnValueRecord[COLUMN_NAMES.IMAGE];
  const personCol = columnValueRecord[COLUMN_NAMES.OWNER];
  const labelCol = columnValueRecord[COLUMN_NAMES.PLATFORM];
  const descCol = columnValueRecord[COLUMN_NAMES.DESCRIPTION];
  const resourcesTypeCol = columnValueRecord[COLUMN_NAMES.RESOURCE_TYPE];
  const platformCol = columnValueRecord[COLUMN_NAMES.PLATFORM];
  const professionCol = columnValueRecord[COLUMN_NAMES.PROFESSION];
  const moreInfoCol = columnValueRecord[COLUMN_NAMES.MORE_INFO];
  const relatedResCol = columnValueRecord[COLUMN_NAMES.RELATED_RESOURCES];

  const relatedResValue = relatedResCol.value && JSON.parse(relatedResCol.value);
  const relatedResourcesIds = relatedResValue?.linkedPulseIds
    ? relatedResValue.linkedPulseIds.map((pulse: { linkedPulseId: Number }) => pulse.linkedPulseId)
    : [];

  const resourcesType = getStatusLabel(resourcesTypeCol);
  const status = getStatusLabel(statusCol);
  const platform = getStatusLabel(platformCol);
  const profession = getTagLabel(professionCol, tagsRecord);

  const personId = JSON.parse(personCol.value!)?.personsAndTeams[0].id;
  const user = users.find(user => user.id === personId);

  const linkUrl = getLinkUrl(linkCol);

  const smallImgId = getAssetId(smallImgCol);
  const imgId = getAssetId(imgCol);

  const { eventTitle, formattedDate } = getLastActivityDate(itemsHistory[id]);

  if (!isDraftMode && isDraft(status.text)) {
    return;
  }

  const result = {
    title: name,
    id,
    linkUrl,
    status: status,
    imgSrc: assetsRecord[imgId] || '',
    smallImgSrc: assetsRecord[smallImgId] || '',
    user,
    label: labelCol.text,
    description: descCol.text,
    isFavorite: favoritesIds.includes(id),
    resourcesType,
    platform,
    profession,
    isNew: isNewItem(status.text, itemsHistory[id]),
    date: formattedDate,
    eventTitle,
    moreInfo: moreInfoCol.text,
    relatedResourcesIds,
  } as Tool;

  return result;
}

export function getPluginsInfo({
  item,
  users,
  isDraftMode,
}: {
  item: Item;
  users: User[];
  isDraftMode: boolean;
}) {
  const { columnValues, id, name } = item;
  const columnValueRecord = getColumnValueRecord(columnValues);

  const linkCol = columnValueRecord[COLUMN_NAMES.LINK];
  const statusCol = columnValueRecord[COLUMN_NAMES.STATUS_THIRD_GROUP];
  const personCol = columnValueRecord[COLUMN_NAMES.OWNER];
  const labelCol = columnValueRecord[COLUMN_NAMES.PLATFORM];
  const descCol = columnValueRecord[COLUMN_NAMES.DESCRIPTION];

  const personId = JSON.parse(personCol.value!)?.personsAndTeams[0].id;
  const user = users.find(user => user.id === personId);

  const linkUrl = getLinkUrl(linkCol);

  if (!isDraftMode && isDraft(statusCol.text)) {
    return;
  }

  const result = {
    title: name,
    id,
    linkUrl,
    status: statusCol.text,
    user,
    label: labelCol.text,
    description: descCol.text,
  };

  return result;
}

export function getUpdatesInfo({
  assetsRecord,
  item,
  tagsRecord,
  isDraftMode,
}: {
  item: Item;
  assetsRecord: Record<string, string>;
  tagsRecord: Record<string, Tag>;
  isDraftMode: boolean;
}) {
  const { columnValues, id, name } = item;
  const columnValueRecord = getColumnValueRecord(columnValues);

  const linkCol = columnValueRecord[COLUMN_NAMES.LINK];
  const statusCol = columnValueRecord[COLUMN_NAMES.STATUS_SECOND_GROUP];
  const professionCol = columnValueRecord[COLUMN_NAMES.PROFESSION];
  const smallImgCol = columnValueRecord[COLUMN_NAMES.SMALL_IMAGE];

  const smallImgId = getAssetId(smallImgCol);

  const linkUrl = getLinkUrl(linkCol);
  const profession = getTagLabel(professionCol, tagsRecord);

  if (!isDraftMode && isDraft(statusCol.text)) {
    return;
  }

  const result = {
    title: name,
    id,
    linkUrl,
    status: statusCol.text,
    profession,
    smallImgSrc: assetsRecord[smallImgId] || '',
  };

  return result;
}

function getLinkUrl(linkCol?: ColumnValue): string {
  if (!linkCol) return '';

  const { value } = linkCol;

  if (!value) return '';

  const parsedValue = JSON.parse(value);

  return parsedValue?.url || '';
}

function getColumnValueRecord(columnValues: ColumnValue[]): Record<string, ColumnValue> {
  return columnValues.reduce((acc, colValue) => {
    const title = colValue.title;
    acc[title] = colValue;
    return acc;
  }, {} as Record<string, ColumnValue>);
}

function getAssetId(columnValue: ColumnValue) {
  if (!columnValue) return '';

  const imgColValue = columnValue.value;
  const parsedImgValue = imgColValue && JSON.parse(imgColValue);

  const assetsId = parsedImgValue?.files[0]?.assetId;

  return assetsId;
}

function getStatusLabel(statusCol: ColumnValue) {
  const parsedValue = statusCol.value && JSON.parse(statusCol.value);
  const id = typeof parsedValue?.index === 'number' ? parsedValue.index : 'N/A';
  return { text: statusCol.text, id, columnId: statusCol.id };
}

function getTagLabel(tagCol: ColumnValue, tagsRecord: Record<string, Tag>) {
  const parsedValue = tagCol.value && JSON.parse(tagCol.value);
  const tags = parsedValue?.tag_ids?.map((id: string) => tagsRecord[id]);

  return tags || [];
}

function isNewItem(status: string, histories: ItemHistory[]) {
  if (status === LABEL_NAMES.ACTIVE) return false;

  const firstEvent = getFirstEvent(histories, { itemCreated: true, itemStatusPublished: true });

  if (!firstEvent) return false;

  return isDateWithinMaxDuration(firstEvent.createdAt);
}

function getLastActivityDate(histories: ItemHistory[]) {
  const result = { eventTitle: '', formattedDate: '' };
  const firstEvent = getFirstEvent(histories, {
    itemCreated: true,
    itemStatusReviewed: true,
    itemStatusUpdated: true,
    itemStatusPublished: true,
    updatedItem: true,
  });

  if (!firstEvent) return result;

  const formattedDate = getFormattedDate({ date: firstEvent.createdAt, withPrefix: true });
  const eventTitle = getEventTitle(firstEvent);

  result.eventTitle = eventTitle;
  result.formattedDate = formattedDate;

  return result;
}

function getEventTitle(itemHistory: ItemHistory) {
  const { event } = itemHistory;
  if (
    event === EventType.Item_created ||
    (event === EventType.Updated_status && itemHistory.label === LABEL_NAMES.PUBLISHED)
  ) {
    return 'Added';
  }

  if (
    event === EventType.Item_updated ||
    (event === EventType.Updated_status && itemHistory.label === LABEL_NAMES.UPDATED)
  ) {
    return 'Updated';
  }

  if (event === EventType.Updated_status && itemHistory.label === LABEL_NAMES.REVIEWED) {
    return 'Reviewed';
  }

  return '';
}

function getFirstEvent(histories: ItemHistory[], scope?: Scope) {
  const { itemCreated, itemStatusPublished, itemStatusReviewed, itemStatusUpdated, updatedItem } =
    scope || {};
  const firstEvent = histories.find(history => {
    const isItemCreated = itemCreated && history.event === EventType.Item_created;
    const isItemPublished =
      itemStatusPublished &&
      history.event === EventType.Updated_status &&
      history.label === LABEL_NAMES.PUBLISHED;
    const isItemStatusUpdated =
      itemStatusUpdated &&
      history.event === EventType.Updated_status &&
      history.label === LABEL_NAMES.UPDATED;
    const isItemReviewed =
      itemStatusReviewed &&
      history.event === EventType.Updated_status &&
      history.label === LABEL_NAMES.REVIEWED;
    const isUpdatedItem = updatedItem && history.event === EventType.Item_updated;

    return (
      isItemCreated || isItemPublished || isItemStatusUpdated || isItemReviewed || isUpdatedItem
    );
  });

  return firstEvent;
}

interface Scope {
  itemCreated?: boolean;
  itemStatusPublished?: boolean;
  itemStatusReviewed?: boolean;
  itemStatusUpdated?: boolean;
  updatedItem?: boolean;
}

function isDraft(status: string) {
  status = status.toLowerCase().trim();

  return status === 'draft' || status === 'ready for publish';
}
