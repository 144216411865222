import { FC } from 'react';
import { useShowMore } from '../../hooks';
import cn from 'classnames';
import { Label, ShowMore } from '..';
import { ReactComponent as FavoriteIcon } from '../../assets/favorite-icon.svg';
import { ReactComponent as ExternalLink } from '../../assets/external-link-icon.svg';
import { ReactComponent as ChevronLink } from '../../assets/chevron-cta-link.svg';
import { Tool } from '../../types';
import { Heading } from 'monday-ui-react-core';
import { appStore } from '../../stores';
import styles from './ListView.module.scss';
import sharedStyles from '../../shared.module.scss';

export const ListView: FC<{ items: Tool[] }> = ({ items }) => {
  const itemsCount = items.length;
  const { visibleItems, handleShowMore } = useShowMore(itemsCount);

  const { updateFavorites, setSelectedItemId } = appStore;

  function openPopup(id: string | null) {
    setSelectedItemId(id);
  }

  return (
    <div className={styles.list}>
      {items.slice(0, visibleItems).map(item => (
        <div key={item.id} className={styles.item}>
          <img src={item.smallImgSrc} className={styles.image} onClick={() => openPopup(item.id)} />
          <div className={styles.content}>
            <div className={styles.block}>
              <div className={styles.labels}>
                <span className={styles.action} onClick={() => openPopup(item.id)}>
                  <Heading
                    type={Heading.types.h4}
                    className={styles.title}
                    value={item.title}
                    ellipsis
                  />
                </span>

                {item.isNew && <Label primary title="New" />}
              </div>

              <span onClick={() => openPopup(item.id)} className={styles.description}>
                {item.description}
              </span>
            </div>

            <div className={styles.date}>
              {item.eventTitle} {item.date}
            </div>

            <div className={styles.inlineWrapper}>
              <div
                className={cn(
                  sharedStyles.icon,
                  sharedStyles.favIcon,
                  item.isFavorite && sharedStyles.iconActive
                )}
                onClick={() => updateFavorites(item.id)}
              >
                <FavoriteIcon />
              </div>

              <a href={item.linkUrl} className={sharedStyles.icon} target="_blank">
                <ExternalLink />
              </a>
              <a className={sharedStyles.icon} onClick={() => openPopup(item.id)}>
                <ChevronLink />
              </a>
            </div>
          </div>
        </div>
      ))}

      {visibleItems < itemsCount && (
        <ShowMore onClick={handleShowMore} marginTop={80} align="center" />
      )}
    </div>
  );
};
