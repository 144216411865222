import qs from 'qs';

export function isPreviewMode() {
  const PREVIEW_KEY = 'preview';
  const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });

  const value = query[PREVIEW_KEY];

  return value === 'true';
}
