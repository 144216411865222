/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface CompletedProps extends React.SVGAttributes<SVGElement> {
size?: string | number;
}
const Completed: React.FC<CompletedProps> = ({size, ...props}) => (
  <svg viewBox="0 0 20 20" fill="currentColor" width={ size || "20" } height={ size || "20" } {...props}>
    <path d="M15.2851 13.7726C16.071 12.6717 16.4935 11.3527 16.4935 10H16.5H17.9935H18C18 10.506 17.952 11.0081 17.8581 11.5C17.6427 12.6284 17.1856 13.7033 16.5113 14.6479C15.5431 16.0043 14.1754 17.0245 12.5993 17.566C11.0231 18.1074 9.31726 18.1432 7.71984 17.6682C6.12242 17.1932 4.71323 16.2312 3.68904 14.9165C2.66485 13.6018 2.0768 12.0001 2.00702 10.335C1.93724 8.66991 2.3892 7.0246 3.29979 5.62883C4.21038 4.23306 5.53414 3.15651 7.08621 2.54951C8.16708 2.1268 9.3211 1.94651 10.4679 2.01369C10.9675 2.04296 11.4656 2.11917 11.9558 2.24275L11.9571 2.24308L11.9555 2.24938L11.5901 3.6975L11.5885 3.70381C10.2769 3.37289 8.89471 3.45985 7.63492 3.95254C6.37512 4.44523 5.30064 5.31905 4.56153 6.45198C3.82241 7.58491 3.45556 8.92038 3.5122 10.2719C3.56884 11.6234 4.04615 12.9235 4.87747 13.9906C5.7088 15.0578 6.85262 15.8386 8.14922 16.2242C9.44583 16.6097 10.8305 16.5807 12.1098 16.1412C13.3891 15.7017 14.4992 14.8736 15.2851 13.7726ZM17.0496 5.01034C17.3315 4.70681 17.3139 4.23226 17.0104 3.95041C16.7068 3.66856 16.2323 3.68613 15.9504 3.98967L10.0305 10.365L8.07619 8.01987C7.81101 7.70166 7.33809 7.65867 7.01988 7.92384C6.70168 8.18901 6.65868 8.66194 6.92386 8.98014L9.42386 11.9801C9.56185 12.1457 9.76443 12.244 9.97991 12.2497C10.1954 12.2555 10.4029 12.1683 10.5496 12.0103L17.0496 5.01034Z"
      fill="currentColor" fillRule="evenodd" clipRule="evenodd" />
  </svg>
);
Completed.displayName = 'Completed';
export default Completed;
/* tslint:enable */
/* eslint-enable */
