/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface PasteProps extends React.SVGAttributes<SVGElement> {
size?: string | number;
}
const Paste: React.FC<PasteProps> = ({size, ...props}) => (
  <svg viewBox="0 0 20 20" fill="currentColor" width={ size || "20" } height={ size || "20" } {...props}>
    <path d="M6.20999 8.91333C6.20999 8.49912 6.54578 8.16333 6.95999 8.16333H12.88C13.2942 8.16333 13.63 8.49912 13.63 8.91333 13.63 9.32754 13.2942 9.66333 12.88 9.66333H6.95999C6.54578 9.66333 6.20999 9.32754 6.20999 8.91333zM6.20999 11.38C6.20999 10.9658 6.54578 10.63 6.95999 10.63H12.88C13.2942 10.63 13.63 10.9658 13.63 11.38 13.63 11.7942 13.2942 12.13 12.88 12.13H6.95999C6.54578 12.13 6.20999 11.7942 6.20999 11.38zM6.20999 13.8467C6.20999 13.4325 6.54578 13.0967 6.95999 13.0967H12.88C13.2942 13.0967 13.63 13.4325 13.63 13.8467 13.63 14.2609 13.2942 14.5967 12.88 14.5967H6.95999C6.54578 14.5967 6.20999 14.2609 6.20999 13.8467z"
      fill="currentColor" fillRule="evenodd" clipRule="evenodd" />
    <path d="M9.92 3.25C9.46471 3.25 9.02807 3.43086 8.70613 3.7528C8.3842 4.07474 8.20333 4.51138 8.20333 4.96667C8.20333 5.38088 7.86755 5.71667 7.45333 5.71667H4.98667C4.9239 5.71667 4.8637 5.7416 4.81932 5.78598C4.77493 5.83037 4.75 5.89057 4.75 5.95333V16.3133C4.75 16.3761 4.77493 16.4363 4.81932 16.4807C4.8637 16.5251 4.9239 16.55 4.98667 16.55H14.8533C14.9161 16.55 14.9763 16.5251 15.0207 16.4807C15.0651 16.4363 15.09 16.3761 15.09 16.3133V5.95333C15.09 5.89057 15.0651 5.83037 15.0207 5.78598C14.9763 5.7416 14.9161 5.71667 14.8533 5.71667H12.3867C11.9725 5.71667 11.6367 5.38088 11.6367 4.96667C11.6367 4.51138 11.4558 4.07474 11.1339 3.7528C10.8119 3.43086 10.3753 3.25 9.92 3.25ZM13.048 4.21667C12.9107 3.64415 12.6177 3.11527 12.1945 2.69214C11.5913 2.0889 10.7731 1.75 9.92 1.75C9.06689 1.75 8.24872 2.0889 7.64547 2.69214C7.22234 3.11527 6.92927 3.64415 6.79198 4.21667H4.98667C4.52607 4.21667 4.08435 4.39964 3.75866 4.72532C3.43297 5.05101 3.25 5.49274 3.25 5.95333V16.3133C3.25 16.7739 3.43297 17.2157 3.75866 17.5413C4.08435 17.867 4.52607 18.05 4.98667 18.05H14.8533C15.3139 18.05 15.7557 17.867 16.0813 17.5413C16.407 17.2157 16.59 16.7739 16.59 16.3133V5.95333C16.59 5.49274 16.407 5.05101 16.0813 4.72532C15.7557 4.39964 15.3139 4.21667 14.8533 4.21667H13.048Z"
      fill="currentColor" fillRule="evenodd" clipRule="evenodd" />
    <path d="M9.92001 3.72333C9.65567 3.72333 9.40217 3.82833 9.21526 4.01524C9.02834 4.20216 8.92334 4.45566 8.92334 4.71999C8.92334 4.91712 8.98179 5.10981 9.09131 5.27371C9.20082 5.43761 9.35648 5.56536 9.5386 5.64079C9.72072 5.71623 9.92111 5.73597 10.1144 5.69751C10.3078 5.65905 10.4854 5.56413 10.6248 5.42474C10.7641 5.28535 10.8591 5.10776 10.8975 4.91443C10.936 4.7211 10.9162 4.52071 10.8408 4.33859C10.7654 4.15647 10.6376 4.00081 10.4737 3.8913C10.3098 3.78178 10.1171 3.72333 9.92001 3.72333Z"
      fill="currentColor" fillRule="evenodd" clipRule="evenodd" />
  </svg>
);
Paste.displayName = 'Paste';
export default Paste;
/* tslint:enable */
/* eslint-enable */
