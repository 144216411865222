import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './components';
import { GoogleOAuthProvider } from '@react-oauth/google';
import './index.scss';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID!}>
    <App />
  </GoogleOAuthProvider>
);
