import { FC } from 'react';
import { ReactComponent as LinkChevron } from '../../assets/link-chevron-icon.svg';
import styles from './ShowMore.module.scss';

interface ShowMoreProps {
  onClick: () => void;
  align?: 'center' | 'left';
  marginTop?: number;
}

export const ShowMore: FC<ShowMoreProps> = ({ onClick, align = 'left', marginTop = 0 }) => {
  return (
    <div
      className={styles.showMore}
      style={{ justifyContent: align, marginTop: `${marginTop}px` }}
      onClick={onClick}
    >
      <span>
        Show More
        <div className={styles.icon}>
          <LinkChevron />
        </div>
      </span>
    </div>
  );
};
