import { FC } from 'react';
import { ReactComponent as NoToolsLogo } from '../../assets/no-tools-logo.svg';
import styles from './EmptyToolsScreen.module.scss';

export const EmptyToolsScreen: FC<{ withFilters?: boolean }> = ({ withFilters }) => {
  return (
    <div className={styles.wrapper}>
      <div>
        <NoToolsLogo />
        <h4 className={styles.title}>We didn't find any results.</h4>
        {withFilters && (
          <span className={styles.text}>Try using different keywords or filters</span>
        )}
      </div>
    </div>
  );
};
