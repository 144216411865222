/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface EnterProps extends React.SVGAttributes<SVGElement> {
size?: string | number;
}
const Enter: React.FC<EnterProps> = ({size, ...props}) => (
  <svg viewBox="0 0 20 20" fill="currentColor" width={ size || "20" } height={ size || "20" } {...props}>
    <path d="M2.25 3C2.25 2.58579 2.58579 2.25 3 2.25H17C17.4142 2.25 17.75 2.58579 17.75 3V17C17.75 17.4142 17.4142 17.75 17 17.75H3C2.58579 17.75 2.25 17.4142 2.25 17V3ZM3.75 3.75V16.25H16.25V3.75H3.75ZM8.91495 8.93119C9.20784 9.22408 9.20784 9.69895 8.91495 9.99185L8.04143 10.8654H11.6154C11.7021 10.8654 11.7852 10.8309 11.8466 10.7696C11.9079 10.7083 11.9423 10.6252 11.9423 10.5384V7.30768C11.9423 6.89346 12.2781 6.55768 12.6923 6.55768C13.1065 6.55768 13.4423 6.89346 13.4423 7.30768V10.5384C13.4423 11.023 13.2498 11.4877 12.9072 11.8303C12.5646 12.1729 12.0999 12.3654 11.6154 12.3654H8.04144L8.91495 13.2389C9.20784 13.5318 9.20784 14.0066 8.91495 14.2995C8.62206 14.5924 8.14718 14.5924 7.85429 14.2995L5.70241 12.1477C5.6961 12.1414 5.68989 12.135 5.6838 12.1285C5.55837 11.9949 5.48136 11.8152 5.48078 11.6176C5.48078 11.6169 5.48077 11.6161 5.48077 11.6154C5.48077 11.6146 5.48078 11.6139 5.48078 11.6131C5.48107 11.5131 5.50096 11.4176 5.53681 11.3304C5.57342 11.2411 5.62796 11.1575 5.70044 11.085L7.85429 8.93119C8.14718 8.63829 8.62206 8.63829 8.91495 8.93119Z"
      fill="currentColor" fillRule="evenodd" clipRule="evenodd" />
  </svg>
);
Enter.displayName = 'Enter';
export default Enter;
/* tslint:enable */
/* eslint-enable */
