/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface CheckListProps extends React.SVGAttributes<SVGElement> {
size?: string | number;
}
const CheckList: React.FC<CheckListProps> = ({size, ...props}) => (
  <svg viewBox="0 0 20 20" fill="currentColor" width={ size || "20" } height={ size || "20" } {...props}>
    <path d="M2 3.88821C2 2.84538 2.84538 2 3.88821 2H16.1618C17.2046 2 18.05 2.84538 18.05 3.88821V16.1618C18.05 17.2046 17.2046 18.05 16.1618 18.05H3.88821C2.84538 18.05 2 17.2046 2 16.1618V3.88821ZM3.88821 3.5C3.67381 3.5 3.5 3.67381 3.5 3.88821V16.1618C3.5 16.3762 3.67381 16.55 3.88821 16.55H16.1618C16.3762 16.55 16.55 16.3762 16.55 16.1618V3.88821C16.55 3.67381 16.3762 3.5 16.1618 3.5H3.88821Z"
      fill="currentColor" fillRule="evenodd" clipRule="evenodd" />
    <path d="M9.32853 5.76801C9.62142 6.0609 9.62142 6.53578 9.32853 6.82867L7.03615 9.12105C6.74326 9.41394 6.26838 9.41394 5.97549 9.12105L5.05854 8.2041C4.76564 7.9112 4.76564 7.43633 5.05854 7.14344 5.35143 6.85054 5.8263 6.85054 6.1192 7.14344L6.50582 7.53006 8.26787 5.76801C8.56076 5.47512 9.03564 5.47512 9.32853 5.76801zM9.32755 11.0917C9.62045 11.3846 9.62045 11.8595 9.32755 12.1524L7.03517 14.4448C6.74228 14.7377 6.26741 14.7377 5.97451 14.4448L5.05756 13.5278C4.76467 13.2349 4.76467 12.7601 5.05756 12.4672 5.35045 12.1743 5.82533 12.1743 6.11822 12.4672L6.50484 12.8538 8.26689 11.0917C8.55979 10.7988 9.03466 10.7988 9.32755 11.0917zM10.5156 7.36279C10.5156 6.94858 10.8514 6.61279 11.2656 6.61279H14.8144C15.2286 6.61279 15.5644 6.94858 15.5644 7.36279 15.5644 7.77701 15.2286 8.11279 14.8144 8.11279H11.2656C10.8514 8.11279 10.5156 7.77701 10.5156 7.36279zM10.5146 13.042C10.5146 12.6278 10.8504 12.292 11.2646 12.292H14.8134C15.2276 12.292 15.5634 12.6278 15.5634 13.042 15.5634 13.4562 15.2276 13.792 14.8134 13.792H11.2646C10.8504 13.792 10.5146 13.4562 10.5146 13.042z"
      fill="currentColor" fillRule="evenodd" clipRule="evenodd" />
  </svg>
);
CheckList.displayName = 'CheckList';
export default CheckList;
/* tslint:enable */
/* eslint-enable */
