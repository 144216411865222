import { FC } from 'react';
import { useDebounceEvent } from 'monday-ui-react-core';
import { ReactComponent as SearchIcon } from '../../assets/search-icon.svg';

import styles from './SearchBar.module.scss';

interface SearchBarProps {
  placeholder?: string;
  value?: string;
  debounceRate?: number;
  onChange?: (value: string) => void;
}

export const SearchBar: FC<SearchBarProps> = ({
  placeholder = 'Search',
  value,
  debounceRate,
  onChange,
}) => {
  const { inputValue, onEventChanged } = useDebounceEvent({
    delay: debounceRate,
    onChange,
    initialStateValue: value,
  });

  return (
    <div className={styles.wrapper}>
      <input
        className={styles.input}
        type="text"
        value={inputValue}
        onChange={onEventChanged}
        placeholder={placeholder}
      />

      <div className={styles.icon}>
        <SearchIcon />
      </div>
    </div>
  );
};
