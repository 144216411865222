/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface AnnouncementProps extends React.SVGAttributes<SVGElement> {
size?: string | number;
}
const Announcement: React.FC<AnnouncementProps> = ({size, ...props}) => (
  <svg viewBox="0 0 20 20" fill="currentColor" width={ size || "20" } height={ size || "20" } {...props}>
    <path d="M16.812 16.4614C16.6504 16.4614 16.4996 16.4183 16.3596 16.3213L15.5409 15.7827C13.2881 14.2776 10.694 13.4115 8.00303 13.2553C8.06205 13.5636 8.15447 13.8644 8.2803 14.1561C8.51729 14.7055 8.85123 15.1902 9.2929 15.6104C9.61607 15.9228 9.62684 16.4291 9.32522 16.7522C9.16363 16.9138 8.94818 17 8.73274 17C8.52806 17 8.33416 16.9246 8.17257 16.7738C7.58009 16.2136 7.11688 15.5457 6.79371 14.7917C6.57789 14.2881 6.43413 13.7653 6.36244 13.2297H5.50102C4.56383 13.2297 3.69126 12.8634 3.02338 12.2063C2.36626 11.5492 2 10.6658 2 9.72864C2 8.79145 2.36626 7.91888 3.02338 7.251C3.69126 6.59388 4.56383 6.22762 5.50102 6.22762L7.11688 6.22762H7.11847C10.1342 6.22731 13.0421 5.34399 15.5517 3.67457L16.3704 3.13595C16.6181 2.97436 16.9413 2.95282 17.1998 3.09286C17.4584 3.2329 17.6307 3.50221 17.6307 3.80384V15.6535C17.6307 15.9551 17.4691 16.2244 17.1998 16.3644C17.0706 16.4291 16.9413 16.4614 16.812 16.4614ZM16.0041 14.1561C13.5588 12.6372 10.7903 11.7754 7.92481 11.6354V7.82193C10.7903 7.68189 13.5588 6.8201 16.0041 5.3012V14.1561ZM5.50102 7.84348H6.30895V11.6138H5.50102C4.99472 11.6138 4.52074 11.4199 4.16525 11.0644C3.80976 10.7089 3.61586 10.2349 3.61586 9.72864C3.61586 9.22234 3.80976 8.74836 4.16525 8.39287C4.52074 8.03738 4.99472 7.84348 5.50102 7.84348Z"
      fill="currentColor" fillRule="evenodd" clipRule="evenodd" />
  </svg>
);
Announcement.displayName = 'Announcement';
export default Announcement;
/* tslint:enable */
/* eslint-enable */
