const KEY_TOKEN = 'token';
const USER_EMAIL = 'userEmail';

export function getUserEmail() {
  return localStorage.getItem(USER_EMAIL) || '';
}

export function getToken() {
  return localStorage.getItem(KEY_TOKEN) || '';
}

export function saveUserData(data: { email: string; token: string }) {
  localStorage.setItem(USER_EMAIL, data.email);
  localStorage.setItem(KEY_TOKEN, data.token);
}

export function removeUserData() {
  localStorage.removeItem(USER_EMAIL);
  localStorage.removeItem(KEY_TOKEN);
}
