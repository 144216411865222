import { Label, Navigation, ShowMore } from '../../ui-kit';
import { Avatar } from 'monday-ui-react-core';
import { useShowMore } from '../../hooks';
import { appStore } from '../../stores';
import styles from './Plugins.module.scss';

export const Plugins = () => {
  const { plugins } = appStore.structure;
  const itemsCount = plugins.length;
  const { visibleItems, handleShowMore } = useShowMore(itemsCount);

  return (
    <div className={styles.plugins}>
      <h2 className={styles.sectionTitle}>Recommended plugins</h2>
      <div className={styles.list}>
        {plugins.slice(0, visibleItems).map((item, i) => (
          <div key={i} className={styles.item}>
            <div className={styles.content}>
              <div className={styles.block}>
                <div className={styles.labels}>
                  <h4 className={styles.title}>{item.title}</h4>
                  <Label title={item.label} />
                </div>

                <span className={styles.description}>{item.description}</span>
              </div>

              <div className={styles.personInfo}>
                <Avatar
                  className={styles.avatar}
                  size={Avatar.sizes.SMALL}
                  src={item?.user?.photoThumbSmall}
                  type={Avatar.types.IMG}
                />

                <span>{item?.user?.name}</span>
              </div>

              <div className={styles.link}>
                <Navigation isLink href={item.linkUrl} text="Open" />
              </div>
            </div>
          </div>
        ))}

        {visibleItems < itemsCount && <ShowMore marginTop={64} onClick={handleShowMore} />}
      </div>
    </div>
  );
};
