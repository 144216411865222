import { toCamelCase } from '../utils';
import {
  ActivityLog,
  ActivityLogData,
  ActivityLogInfo,
  Item,
  ItemHistory,
  EventType,
  Column,
  UpdateData,
} from '../types';
import { COLUMN_NAMES, GROUP_NAMES, HISTORY_LABELS } from '../constants';
import { getActivityLogs } from './api';

export async function combineHistory({
  items,
  columns,
  boardId,
}: {
  items: Item[];
  columns: Column[];
  boardId: number;
}): Promise<Record<string, ItemHistory[]>> {
  const activityLogs = await getActivityLogsByGroups({ items, columns, boardId });
  const activityLogsInfo = extractActivityLogsResponse(activityLogs);

  const history: Record<string, ItemHistory[]> = items.reduce((acc, item) => {
    const { id, createdAt } = item;
    const activityLogs = activityLogsInfo[id] || [];

    const createdItemInfo = {
      createdAt,
      userId: item.creatorId,
      event: EventType.Item_created as const,
    };

    const transformedUpdates = item.updates.filter(isUpdateValid).map(update => ({
      createdAt: update.createdAt,
      event: EventType.Item_updated as const,
      userId: update.creator.id,
      message: update.textBody?.replace(/\//g, ''),
    }));

    acc[id] = [createdItemInfo, ...activityLogs, ...transformedUpdates];
    acc[id].sort((x, y) => new Date(y.createdAt).getTime() - new Date(x.createdAt).getTime());

    return acc;
  }, {} as Record<string, ItemHistory[]>);

  return history;
}

function extractActivityLogsResponse(activityLogs: ActivityLog[]) {
  const activityLogsInfo = activityLogs.reduce((acc, log) => {
    const { data, createdAt, userId } = log;
    const parsedData = toCamelCase(JSON.parse(data)) as ActivityLogData;
    const itemId = parsedData.pulseId;

    const { value } = parsedData;
    const { label } = value;

    if (!HISTORY_LABELS.includes(label.text)) {
      return acc;
    }

    const result = {
      createdAt: new Date(+createdAt / 10000).toISOString(),
      userId,
      label: label.text || '',
      event: EventType.Updated_status as const,
    };

    acc[itemId] = acc[itemId] ? [...acc[itemId], result] : [result];
    return acc;
  }, {} as Record<string, ActivityLogInfo[]>);

  return activityLogsInfo;
}

async function getActivityLogsByGroups({
  boardId,
  items,
  columns,
}: {
  boardId: number;
  items: Item[];
  columns: Column[];
}) {
  const toolGroupItemIds = items
    .filter(item => GROUP_NAMES.TOOLS.includes(item.group.title))
    .map(item => +item.id);

  const updateGroupItemIds = items
    .filter(item => GROUP_NAMES.UPDATES === item.group.title)
    .map(item => +item.id);

  const toolGroupStatusColId =
    columns.find(column => column.title === COLUMN_NAMES.STATUS_FIRST_GROUP)?.id || '';
  const updateGroupStatusColId =
    columns.find(column => column.title === COLUMN_NAMES.STATUS_SECOND_GROUP)?.id || '';

  const toolsActivityLogs = await getActivityLogs({
    boardId,
    itemsId: toolGroupItemIds,
    columnId: toolGroupStatusColId,
  });

  const updateActivityLogs = await getActivityLogs({
    boardId,
    itemsId: updateGroupItemIds,
    columnId: updateGroupStatusColId,
  });

  const activityLogs = [...toolsActivityLogs, ...updateActivityLogs];

  return activityLogs;
}

function isUpdateValid(update: UpdateData) {
  const VALID_UPDATE_FIRST_SYMBOL = '/';
  const clearText = update.textBody.trim().replace(/\\n/g, '');

  return clearText[0] === VALID_UPDATE_FIRST_SYMBOL;
}
