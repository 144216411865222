/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface NumbersProps extends React.SVGAttributes<SVGElement> {
size?: string | number;
}
const Numbers: React.FC<NumbersProps> = ({size, ...props}) => (
  <svg viewBox="0 0 20 20" fill="currentColor" width={ size || "20" } height={ size || "20" } {...props}>
    <path d="M4.31262 13.9836C4.23983 13.9563 4.16084 13.9498 4.08458 13.965C4.00832 13.9802 3.93783 14.0164 3.88109 14.0696C3.82435 14.1228 3.78362 14.1907 3.7635 14.2659C3.69207 14.5326 3.41793 14.6909 3.15119 14.6195C2.88444 14.5481 2.72611 14.2739 2.79754 14.0072C2.86592 13.7518 3.00441 13.5207 3.19733 13.3399C3.39026 13.1591 3.62992 13.0359 3.8892 12.9843C4.14849 12.9326 4.41706 12.9545 4.66453 13.0476C4.912 13.1406 5.1285 13.3011 5.28954 13.5107C5.45057 13.7204 5.54972 13.971 5.57576 14.2341C5.60181 14.4972 5.55371 14.7623 5.43691 14.9995C5.38537 15.1042 5.32136 15.2015 5.24664 15.2897C5.32092 15.3771 5.38466 15.4736 5.43613 15.5774C5.55343 15.8138 5.60234 16.0784 5.57735 16.3411C5.55235 16.6039 5.45444 16.8545 5.29466 17.0645C5.13487 17.2746 4.91957 17.4359 4.67303 17.5302C4.42649 17.6245 4.15851 17.648 3.89931 17.5981C3.64011 17.5482 3.40001 17.4269 3.20607 17.2479C3.01213 17.0688 2.87207 16.8391 2.80168 16.5847C2.72804 16.3186 2.8841 16.0432 3.15024 15.9695C3.41638 15.8959 3.69183 16.0519 3.76547 16.3181C3.78617 16.3929 3.82737 16.4605 3.88441 16.5131C3.94145 16.5658 4.01207 16.6015 4.0883 16.6161C4.16454 16.6308 4.24335 16.6239 4.31587 16.5962C4.38838 16.5684 4.4517 16.521 4.4987 16.4592C4.54569 16.3974 4.57449 16.3237 4.58184 16.2464C4.58919 16.1691 4.57481 16.0913 4.54031 16.0218C4.50581 15.9523 4.45256 15.8937 4.38658 15.8528C4.32059 15.8119 4.24449 15.7903 4.16686 15.7903C3.89074 15.7904 3.66679 15.5667 3.66663 15.2906C3.66647 15.0145 3.89016 14.7905 4.16628 14.7903C4.16632 14.7903 4.16636 14.7903 4.1664 14.7903C4.24412 14.7902 4.32027 14.7684 4.38624 14.7273C4.45225 14.6862 4.50544 14.6275 4.53979 14.5577C4.57414 14.488 4.58829 14.41 4.58063 14.3326C4.57297 14.2552 4.54381 14.1815 4.49644 14.1198C4.44908 14.0582 4.3854 14.011 4.31262 13.9836Z"
      fill="currentColor" fillRule="evenodd" clipRule="evenodd" />
    <path d="M7.60004 5.53845H17.2923M7.60004 10.3846H17.2923M7.60004 15.2308H17.2923" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"
    />
    <path d="M3.25 9.79032C3.25 9.54721 3.34658 9.31405 3.51849 9.14214C3.69039 8.97023 3.92355 8.87366 4.16667 8.87366C4.40978 8.87366 4.64294 8.97023 4.81485 9.14214C4.98676 9.31405 5.08333 9.54721 5.08333 9.79032C5.08343 10.087 4.98257 10.375 4.79733 10.6068L4.02367 11.1152L3.25 11.6237H5.08333"
      stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3.20001 3.5C2.92387 3.5 2.70001 3.72386 2.70001 4C2.70001 4.27614 2.92387 4.5 3.20001 4.5H3.70001V6.5H3.25C2.97386 6.5 2.75 6.72386 2.75 7C2.75 7.27614 2.97386 7.5 3.25 7.5H4.20001H5.08333C5.35948 7.5 5.58333 7.27614 5.58333 7C5.58333 6.72386 5.35948 6.5 5.08333 6.5H4.70001V4.375C4.70001 4.0889 4.54783 3.85553 4.35357 3.70983C4.16206 3.5662 3.92593 3.5 3.70001 3.5H3.20001Z"
      fill="currentColor" fillRule="evenodd" clipRule="evenodd" />
  </svg>
);
Numbers.displayName = 'Numbers';
export default Numbers;
/* tslint:enable */
/* eslint-enable */
