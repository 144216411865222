import { FC } from 'react';
import cn from 'classnames';
import styles from './Label.module.scss';

type LabelProps = {
  title: string;
  primary?: boolean;
  kind?: 'fill' | 'line';
};

export const Label: FC<LabelProps> = ({ title, primary = false, kind = 'fill' }) => {
  return <div className={cn(styles.label, primary && styles.primary, styles[kind])}>{title}</div>;
};
